"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.split");
var _typeof2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _elementUi = require("element-ui");
var responseDataError400 = function responseDataError400(key1, key2) {
  console.log('key1:' + key1);
  console.log('key2:' + key2);
  var str = '';
  switch (key1) {
    case 'None':
      switch (key2) {
        case 'internal_error':
          str = '系统错误，请联络管理人员';
          break;
      }
      break;
    case 'internal_error':
      switch (key2) {
        case 'internal_error':
          str = '系统错误，请联络管理人员';
          break;
        case 'device_duplicate':
          str = '编号重複';
          break;
      }
      break;
    case 'device_duplicate':
      switch (key2) {
        case 'device_duplicate':
          str = '编号重複';
          break;
      }
      break;
    case 'invalid_token':
      switch (key2) {
        case 'invalid_token':
          str = '登入凭证已过期，请重新登入';
          break;
      }
      break;
    case 'invalid_request':
      switch (key2) {
        case 'invalid_request':
          str = '参数无效';
          break;
      }
      break;
    case 'invalid_refresh_token':
      switch (key2) {
        case 'invalid_refresh_token':
          str = '登入凭证已失效，请重新登入';
          break;
      }
      break;
    case 'invalid_password':
      switch (key2) {
        case 'invalid_password':
          str = '密码无效';
          break;
      }
      break;
    case 'field_require':
      switch (key2) {
        case 'field_require':
          str = '这个栏位是必填的';
          break;
      }
      break;
    case 'field_null':
      switch (key2) {
        case 'field_null':
          str = '这个栏位是必填的';
          break;
      }
      break;
    case 'field_invalid':
      switch (key2) {
        case 'field_invalid':
          str = '这个栏位无效';
          break;
      }
      break;
    case 'field_blank':
      switch (key2) {
        case 'field_blank':
          str = '这个栏位是必填的';
          break;
      }
      break;
    case 'field_max_value':
      switch (key2) {
        case 'field_max_value':
          str = '这个栏位输入非法数值';
          break;
      }
      break;
    case 'field_min_value':
      switch (key2) {
        case 'field_min_value':
          str = '这个栏位输入非法数值';
          break;
      }
      break;
    case 'field_max_length':
      switch (key2) {
        case 'field_max_length':
          str = '这个栏位输入非法数值';
          break;
      }
      break;
    case 'field_min_length':
      switch (key2) {
        case 'field_min_length':
          str = '这个栏位输入非法数值';
          break;
      }
      break;
    case 'field_max_string_length':
      switch (key2) {
        case 'field_blank':
          str = '输入内容字数过长';
          break;
      }
      break;
    case 'password_not_match':
      switch (key2) {
        case 'password_not_match':
          str = '原始密码不正确';
          break;
      }
      break;
    case 'bad_verify_type':
      switch (key2) {
        case 'bad_verify_type':
          str = '验证类型格式无效';
          break;
      }
      break;
    case 'bad_phone':
      switch (key2) {
        case 'bad_phone':
          str = '电话格式无效';
          break;
      }
      break;
    case 'bad_date':
      switch (key2) {
        case 'bad_date':
          str = '日期格式无效';
          break;
      }
      break;
    case 'bad_password':
      switch (key2) {
        case 'bad_password':
          str = '密码格式无效';
          break;
      }
      break;
    case 'bad_old_password':
      switch (key2) {
        case 'bad_old_password':
          str = '旧密码不正确';
          break;
      }
      break;
    case 'same_password':
      switch (key2) {
        case 'same_password':
          str = '新密码与旧密码相同';
          break;
      }
      break;
    case 'bad_promotion_code':
      switch (key2) {
        case 'bad_promotion_code':
          str = '推荐码是必填的';
          break;
      }
      break;
    case 'bad_province_code':
      switch (key2) {
        case 'bad_province_code':
          str = '省代码无效';
          break;
      }
      break;
    case 'bad_city_code':
      switch (key2) {
        case 'bad_city_code':
          str = '市代码无效';
          break;
      }
      break;
    case 'bad_district_code':
      switch (key2) {
        case 'bad_district_code':
          str = '区代码无效';
          break;
      }
      break;
    case 'bad_source':
      switch (key2) {
        case 'bad_source':
          str = '来源数据无效';
          break;
      }
      break;
    case 'bad_count':
      switch (key2) {
        case 'bad_count':
          str = '数量无效';
          break;
      }
      break;
    case 'bad_value_type':
      switch (key2) {
        case 'bad_value_type':
          str = '值类型格式无效';
          break;
      }
      break;
    case 'bad_js_code':
      switch (key2) {
        case 'bad_js_code':
          str = 'Js代码无效';
          break;
      }
      break;
    case 'bad_file_size':
      switch (key2) {
        case 'bad_file_size':
          str = '档案大小无效';
          break;
      }
      break;
    case 'bad_file_type':
      switch (key2) {
        case 'bad_file_type':
          str = '档案类型格式无效';
          break;
      }
      break;
    case 'bad_device_type':
      switch (key2) {
        case 'bad_device_type':
          str = '装置类型格式无效';
          break;
      }
      break;
    case 'bad_ota_type':
      switch (key2) {
        case 'bad_ota_type':
          str = 'Ota类型格式无效';
          break;
      }
      break;
    case 'bad_ota_status':
      switch (key2) {
        case 'bad_ota_status':
          str = 'Ota状态无效';
          break;
      }
      break;
    case 'bad_sex':
      switch (key2) {
        case 'bad_sex':
          str = '性别无效';
          break;
      }
      break;
    case 'bad_birthday':
      switch (key2) {
        case 'bad_birthday':
          str = '出生日期格式无效';
          break;
      }
      break;
    case 'bad_skin_allergic':
      switch (key2) {
        case 'bad_skin_allergic':
          str = '皮肤过敏格式无效';
          break;
      }
      break;
    case 'bad_tts_agree':
      switch (key2) {
        case 'bad_tts_agree':
          str = 'TTS调理知情同意书格式无效';
          break;
      }
      break;
    case 'bad_url':
      switch (key2) {
        case 'bad_url':
          str = 'Url格式无效';
          break;
      }
      break;
    case 'bad_source_version':
      switch (key2) {
        case 'bad_source_version':
          str = '来源版本无效';
          break;
      }
      break;
    case 'account_not_registered':
      switch (key2) {
        case 'account_not_registered':
          str = '帐号未注册';
          break;
      }
      break;
    case 'account_registered':
      switch (key2) {
        case 'account_registered':
          str = '帐号已经注册';
          break;
      }
      break;
    case 'shipping_address_does_not_exist':
      switch (key2) {
        case 'shipping_address_does_not_exist':
          str = '收货地址不存在';
          break;
      }
      break;
    case 'verification_code_does_not_exist':
      switch (key2) {
        case 'verification_code_does_not_exist':
          str = '验证码不存在';
          break;
      }
      break;
    case 'verification_code_has_expired':
      switch (key2) {
        case 'verification_code_has_expired':
          str = '验证码已过期';
          break;
      }
      break;
    case 'verification_code_has_been_redeemed':
      switch (key2) {
        case 'verification_code_has_been_redeemed':
          str = '验证码已被使用';
          break;
      }
      break;
    case 'verification_code_too_many_frequently':
      switch (key2) {
        case 'verification_code_too_many_frequently':
          str = '验证码发送频率过高，请稍后再试';
          break;
      }
      break;
    case 'failed_to_send_verification_code':
      switch (key2) {
        case 'failed_to_send_verification_code':
          str = '无法发送验证码';
          break;
      }
      break;
    case 'goods_does_invalid':
      switch (key2) {
        case 'goods_does_invalid':
          str = '商品无效';
          break;
      }
      break;
    case 'goods_quantity_does_invalid':
      switch (key2) {
        case 'goods_quantity_does_invalid':
          str = '商品数量无效';
          break;
      }
      break;
    case 'goods_does_not_exist':
      switch (key2) {
        case 'goods_does_not_exist':
          str = '商品不存在';
          break;
      }
      break;
    case 'shopping_cart_does_not_exist':
      switch (key2) {
        case 'shopping_cart_does_not_exist':
          str = '购物车内容不存在';
          break;
      }
      break;
    case 'system_configuration_does_not_exist':
      switch (key2) {
        case 'system_configuration_does_not_exist':
          str = '系统参数设定无效';
          break;
      }
      break;
    case 'online_order_does_not_exist':
      switch (key2) {
        case 'online_order_does_not_exist':
          str = '线上订单不存在';
          break;
      }
      break;
    case 'payment_does_not_exist':
      switch (key2) {
        case 'payment_does_not_exist':
          str = '支付订单不存在';
          break;
      }
      break;
    case 'device_does_not_exist':
      switch (key2) {
        case 'device_does_not_exist':
          str = '装置不存在';
          break;
      }
      break;
    case 'ota_file_does_not_exist':
      switch (key2) {
        case 'ota_file_does_not_exist':
          str = 'OTA档案不存在';
          break;
      }
      break;
    case 'ota_does_not_exist':
      switch (key2) {
        case 'ota_does_not_exist':
          str = 'OTA不存在';
          break;
      }
      break;
    case 'user_does_not_exist':
      switch (key2) {
        case 'user_does_not_exist':
          str = '会员不存在';
          break;
      }
      break;
    case 'plan_does_not_exist':
      switch (key2) {
        case 'plan_does_not_exist':
          str = 'C端方案不存在';
          break;
      }
      break;
    case 'login_fail_over_times':
      switch (key2) {
        case 'login_fail_over_times':
          str = '登入失败超过6次';
          break;
      }
      break;
    case 'unable_to_login_with_provided_credentials':
      switch (key2) {
        case 'unable_to_login_with_provided_credentials':
          str = '使用提供的凭证无法登入';
          break;
      }
      break;
    case 'unsupported':
      switch (key2) {
        case 'unsupported':
          str = '不支持';
          break;
      }
      break;
    case 'request_unavailable':
      switch (key2) {
        case 'request_unavailable':
          str = '请稍后重试';
          break;
      }
      break;
    case 'tooManyResources':
      switch (key2) {
        case 'tooManyResources':
          str = '请求次数超过限制';
          break;
      }
      break;
    case '["User doesn\'t exist."]':
      switch (key2) {
        case 'user_not_exist':
          str = '会员不存在';
          break;
      }
      break;
    case 'cannot_be_greater_than_9999':
      switch (key2) {
        case 'cannot_be_greater_than_9999':
          str = '不能大于9999';
          break;
      }
      break;
    case 'cannot_be_less_than_0':
      switch (key2) {
        case 'cannot_be_less_than_0':
          str = '不能小于0';
          break;
      }
      break;
    case 'max_decimal_places':
      switch (key2) {
        case 'max_decimal_places':
          str = '最大小数位数';
          break;
      }
      break;
    case 'invalid_image':
      switch (key2) {
        case 'invalid_image':
          str = '请上传有效的图像。您上传的文件不是图像，或者是损坏的图像。';
          break;
      }
      break;
    case 'icon_invalid_extension':
      switch (key2) {
        case 'icon_invalid_extension':
          str = '仅支持JPG、JPEG和PNG';
          break;
      }
      break;
    case 'duplicate_products':
      switch (key2) {
        case 'duplicate_products':
          str = '重复的产品';
          break;
      }
      break;
    case 'duplicate_version':
      switch (key2) {
        case 'duplicate_version':
          str = '重复的版本';
          break;
      }
      break;
    case 'duplicate_file':
      switch (key2) {
        case 'duplicate_file':
          str = '重复的档案';
          break;
      }
      break;
    case 'device_is_none':
      switch (key2) {
        case 'device_is_none':
          str = '装置不存在';
          break;
      }
      break;
    case 'save_error':
      switch (key2) {
        case 'save_error':
          str = '储存失败';
          break;
      }
      break;
    case 'wxpay_error':
      switch (key2) {
        case 'wxpay_error':
          str = '微信支付失败';
          break;
      }
      break;
    case 'upload_error':
      switch (key2) {
        case 'upload_error':
          str = '上传失败';
          break;
      }
      break;
    case 'oss_error':
      switch (key2) {
        case 'oss_error':
          str = '上传阿里云OSS档案失败';
          break;
      }
      break;
    case 'oss_delete_error':
      switch (key2) {
        case 'oss_delete_error':
          str = '删除阿里云OSS档案失败';
          break;
      }
      break;
    case 'file_delete_error':
      switch (key2) {
        case 'file_delete_error':
          str = '删除档案失败';
          break;
      }
      break;
    case 'wxpay_callback_decode_error':
      switch (key2) {
        case 'wxpay_callback_decode_error':
          str = '微信支付解码失败';
          break;
      }
      break;
    case 'wxpay_query_error':
      switch (key2) {
        case 'wxpay_query_error':
          str = '微信支付请求失败';
          break;
      }
      break;
    case 'shopping_cart_ids_not_match':
      switch (key2) {
        case 'shopping_cart_ids_not_match':
          str = '购物车商品匹配失败';
          break;
      }
      break;
    case 'goods_max_quantity':
      switch (key2) {
        case 'goods_max_quantity':
          str = '商品数量超过限制';
          break;
      }
      break;
    case 'is_package_opened_error':
      switch (key2) {
        case 'is_package_opened_error':
          str = '产品包已打开';
          break;
      }
      break;
    case 'status_error':
      switch (key2) {
        case 'status_error':
          str = '请确认订单状态是否合法';
          break;
      }
      break;
    case 'Insufficient_permissions':
      switch (key2) {
        case 'Insufficient_permissions':
          str = '权限不足';
          break;
      }
      break;
    case 'do_not_action':
      switch (key2) {
        case 'do_not_action':
          str = '非法操作';
          break;
      }
      break;
    case 'Empty_attribute':
      switch (key2) {
        case 'Empty_attribute':
          str = '属性不能为空';
          break;
      }
      break;
    case 'status_not':
      switch (key2) {
        case 'status_not':
          str = '请确认状态是否合法';
          break;
      }
      break;
    case 'device_binding_error':
      switch (key2) {
        case 'device_binding_error':
          str = '装置绑定失败';
          break;
      }
      break;
    case 'device_error_not_device':
      switch (key2) {
        case 'device_error_not_device':
          str = '此装置不存在';
          break;
      }
      break;
    case 'disc_numbers_is_null':
      switch (key2) {
        case 'disc_numbers_is_null':
          str = '控释盘数量不足';
          break;
      }
      break;
    case 'disc_binding_error':
      switch (key2) {
        case 'disc_binding_error':
          str = '控释盘绑定失败';
          break;
      }
      break;
    case 'disc_error_not_disc':
      switch (key2) {
        case 'disc_error_not_disc':
          str = '控释盘不存在';
          break;
      }
      break;
    case 'disc_numbers':
      switch (key2) {
        case 'disc_number_not_match':
          str = '控释盘编号不正确';
          break;
      }
      break;
    case 'disc_number_not_match':
      switch (key2) {
        case 'disc_number_not_match':
          str = '控释盘编号数量不匹配';
          break;
      }
      break;
    case 'disc_number':
      switch (key2) {
        case 'disc_duplicate':
          str = '不能选择已出货控释盘编号';
          break;
        case 'disc_number_not_match':
          str = '控释盘编号不正确';
          break;
      }
      break;
    case 'device_not_match':
      switch (key2) {
        case 'device_not_match':
          str = '装置不匹配';
          break;
      }
      break;
    case 'device_number':
      switch (key2) {
        case 'device_not_match':
          str = '设备编号不正确';
          break;
        case 'device_duplicate':
          str = '不能选择已出货设备编号';
          break;
        case 'device_already_exist':
          str = '检测设备编号已存在';
          break;
      }
      break;
    case 'error':
      switch (key2) {
        case 'error':
          str = '系统错误，请联络管理人员';
          break;
      }
      break;
    case 'success':
      switch (key2) {
        case 'success':
          str = 'success';
          break;
      }
      break;
    case 'amount':
      switch (key2) {
        case 'amount_not_match':
          str = '金额不正确';
          break;
      }
      break;
    case 'quantity':
      switch (key2) {
        case 'Ensure this value is greater than or equal to -10000.':
        case 'Ensure this value is less than or equal to 10000.':
          str = '请输入正确的库存数量';
          break;
      }
      break;
    case 'ota_type':
      switch (key2) {
        case 'bad_url':
          str = '请输入正确的固件档案阿里云地址';
          break;
      }
      break;
    case 'value':
      switch (key2) {
        case 'bad_value_type':
          str = '内容不可为空';
          break;
      }
      break;
    case 'sort_order':
      switch (key2) {
        case 'cannot_be_greater_than_9999':
          str = '输入的数字不应大于9999';
          break;
      }
      break;
    case 'non_field_errors':
      switch (key2) {
        case 'duplicate_version':
          str = '该版本号已存在';
          break;
        case 'ota_file_does_not_exist':
          str = '阿里云档案错误地址';
          break;
      }
      break;
    case 'goods_online_order':
      switch (key2) {
        case 'is_package_opened_error':
          str = '已打开产品包，无法申请退货';
          break;
      }
      break;
    case 'mobile':
      switch (key2) {
        case 'bad_phone':
          str = '手机号格式错误';
          break;
      }
      break;
    case 'point_greater_amount_error':
      switch (key2) {
        case 'point_greater_amount_error':
          str = '商品促销价总金额不能低于或等于使用积分';
          break;
      }
      break;
    case 'sale_price_greater_origin_price_error':
      switch (key2) {
        case 'sale_price_greater_origin_price_error':
          str = '促销价不能大于售价';
          break;
      }
      break;
    //店鋪管理    
    case 'default_store_health_user':
      switch (key2) {
        case 'bad_default_store_health_user':
          str = '默认健管师不存在或手机号有异动，请重新选取';
          break;
      }
      break;
  }
  return str;
};
_vue.default.prototype.errorHandle = function (res) {
  var str = '';
  var _split = [];
  switch (res.status_code * 1) {
    case 400:
      Object.keys(res.error_message).forEach(function (key) {
        if ((0, _typeof2.default)(res.error_message[key]) === 'object') {
          res.error_message[key].forEach(function (item) {
            _split = item.split(':');
            if (!_split.length) return;
            str = responseDataError400(key, _split[0]);
            (0, _elementUi.Message)({
              type: 'warning',
              message: "\n                <h3 style=\"margin:0;\">".concat(str, " ").concat(_split[1] ? ": ".concat(_split[1]) : '', "</h3>\n              "),
              dangerouslyUseHTMLString: true,
              duration: 3000,
              showClose: 1
            });
          });
        } else {
          str = responseDataError400(key, res.error_message[key]);
          (0, _elementUi.Message)({
            type: 'warning',
            message: "\n              <h3 style=\"margin:0;\">".concat(str, "</h3>\n            "),
            dangerouslyUseHTMLString: true,
            duration: 3000,
            showClose: 1
          });
        }
      });
      break;
  }

  // Message({
  //   // success/warning/info/error
  //   type: 'error',
  //   message: `
  //   <h3 style="margin-top:0">code : ${res.code}</h3> <br>
  //   <h3 style="margin-top:0">status_code : ${res.status_code}</h3> <br>
  //   <h3 style="margin-top:0">return_code : ${str}</h3> <br>
  //   <h3 style="margin-top:0">error_message:</h3> 
  //   <pre>${JSON.stringify(res.error_message)}</pre>
  //   `,
  //   dangerouslyUseHTMLString: true,
  //   duration: 0,
  //   showClose: 1
  // })
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrg = createOrg;
exports.deleteOrg = deleteOrg;
exports.getDoctorOrg = getDoctorOrg;
exports.getOrg = getOrg;
exports.getOrgAll = getOrgAll;
exports.getOrgList = getOrgList;
exports.updateOrg = updateOrg;
var _request = _interopRequireDefault(require("@/utils/request"));
function getOrgAll(query) {
  return (0, _request.default)({
    url: '/system/organization/',
    method: 'get',
    params: query
  });
}
function getOrgList(query) {
  return (0, _request.default)({
    url: '/system/organization/',
    method: 'get',
    params: query
  });
}
function createOrg(data) {
  return (0, _request.default)({
    url: '/system/organization/',
    method: 'post',
    data: data
  });
}
function updateOrg(id, data) {
  return (0, _request.default)({
    url: "/system/organization/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteOrg(id) {
  return (0, _request.default)({
    url: "/system/organization/".concat(id, "/"),
    method: 'delete'
  });
}
function getOrg(id) {
  return (0, _request.default)({
    url: "/system/organization/".concat(id, "/"),
    method: 'get'
  });
}
function getDoctorOrg(query) {
  return (0, _request.default)({
    url: "/system/qudao/",
    method: 'get',
    params: query
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCaseSharing = createCaseSharing;
exports.createNews = createNews;
exports.createNewsImage = createNewsImage;
exports.createTemplate = createTemplate;
exports.getCaseSharingList = getCaseSharingList;
exports.getLabelsList = getLabelsList;
exports.getNewsList = getNewsList;
exports.getTemplate = getTemplate;
exports.getTemplateAnswers = getTemplateAnswers;
exports.updateCaseSharingt = updateCaseSharingt;
exports.updateNews = updateNews;
exports.updateTemplate = updateTemplate;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
// 病例分享
function getCaseSharingList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/case_sharing"),
    method: 'get',
    params: query
  });
}
// 新增病例分享
function createCaseSharing(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/case_sharing"),
    method: 'post',
    data: data
  });
}
// 修改病例分享
function updateCaseSharingt(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/case_sharing/").concat(id),
    method: 'put',
    data: data
  });
}
// 標籤
function getLabelsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/labels"),
    method: 'get',
    params: query
  });
}
// -----------------------------------------------
// 健康專欄 / 焦點新聞
function getNewsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/news"),
    method: 'get',
    params: query
  });
}
// 新增健康專欄 / 焦點新聞
function createNews(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/news"),
    method: 'post',
    data: data
  });
}
// 修改健康專欄 / 焦點新聞
function updateNews(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/news/").concat(id),
    method: 'put',
    data: data
  });
}
// 健康專欄 / 焦點新聞圖片上傳
function createNewsImage(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/news/image"),
    method: 'post',
    data: data
  });
}
// 查詢問卷模板
function getTemplate(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/template"),
    method: 'get',
    params: query
  });
}
// 新增問卷模板
function createTemplate(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/template"),
    method: 'post',
    data: data
  });
}
// 修改問卷模板
function updateTemplate(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/template/").concat(id),
    method: 'put',
    data: data
  });
}
// 取得問卷模板答案統計
function getTemplateAnswers(id) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/template/").concat(id, "/answers"),
    method: 'get'
    // params: query
  });
}
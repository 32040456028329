"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    perms: [],
    dept: '',
    issuperuser: ''
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_PERMS: function SET_PERMS(state, perms) {
    state.perms = perms;
  },
  SET_DEPT: function SET_DEPT(state, dept) {
    state.dept = dept;
  },
  SET_ISSUPERUSER: function SET_ISSUPERUSER(state, issuperuser) {
    state.issuperuser = issuperuser;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username,
        password: password
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.access);
        (0, _auth.setToken)(data.access);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('验证失败,重新登陆.');
        }
        var perms = data.perms,
          name = data.name,
          avatar = data.avatar,
          dept = data.dept,
          issuperuser = data.issuperuser;

        // perms must be a non-empty array
        if (!perms || perms.length <= 0) {
          reject('没有任何权限!');
        }
        commit('SET_PERMS', perms);
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        commit('SET_DEPT', dept);
        commit('SET_ISSUPERUSER', issuperuser);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        (0, _auth.removeToken)(); // must remove  token  first
        (0, _router.resetRouter)();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
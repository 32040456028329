"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.typed.uint8-array");
var _xlsx = _interopRequireDefault(require("xlsx"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    beforeUpload: Function,
    // eslint-disable-line
    onSuccess: Function,
    // eslint-disable-line
    onFail: Function,
    // eslint-disable-line
    isCheckHeader: {
      type: Boolean,
      default: false
    },
    checkHeaderArray: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  methods: {
    generateData: function generateData(_ref) {
      var header = _ref.header,
        results = _ref.results;
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    resetData: function resetData() {
      this.onFail();
    },
    handleDrop: function handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      var files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!');
        return;
      }
      var rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error('Only supports upload .xlsx, .xls, .csv suffix files');
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover: function handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload: function handleUpload() {
      this.$refs['excel-upload-input'].click();
    },
    handleClick: function handleClick(e) {
      var files = e.target.files;
      var rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      if (!this.isExcel(rawFile)) {
        this.$message.error('Only supports upload .xlsx, .xls, .csv suffix files');
        return;
      }
      this.upload(rawFile);
    },
    upload: function upload(rawFile) {
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      var before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData: function readerData(rawFile) {
      var _this = this;
      this.loading = true;
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          // const workbook = XLSX.read(data, { type: 'array' })
          var binary = '';
          var bytes = new Uint8Array(data);
          // console.log('bytes=', bytes)
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var workbook;
          if (!isTxt) {
            workbook = _xlsx.default.read(binary, {
              type: 'binary',
              codepage: 936
            });
          } else {
            workbook = _xlsx.default.read(data, {
              type: 'array'
            });
          }
          // console.log('binary', binary)
          // console.log('work', workbook)
          var firstSheetName = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[firstSheetName];
          var header = _this.getHeaderRow(worksheet);
          // console.log('header', header)
          if (_this.isCheckHeader) {
            if (_this.checkHeaderArray.length) {
              if (_this.checkHeaderArray.every(function (item) {
                return header.includes(item);
              })) {
                var results = _xlsx.default.utils.sheet_to_json(worksheet);
                // console.log('result', results)
                _this.generateData({
                  header: header,
                  results: results
                });
                _this.loading = false;
                resolve();
              } else {
                _this.$message({
                  message: '数据不合法，请重新选择',
                  type: 'error'
                });
                _this.resetData();
                _this.loading = false;
                reject();
              }
            }
          } else {
            var _results = _xlsx.default.utils.sheet_to_json(worksheet);
            // console.log('result', results)
            _this.generateData({
              header: header,
              results: _results
            });
            _this.loading = false;
            resolve();
          }
        };
        var isTxt = rawFile.name.split('.').reverse()[0] === 'txt'; // 判断是否是 txt
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow: function getHeaderRow(sheet) {
      var headers = [];
      var range = _xlsx.default.utils.decode_range(sheet['!ref']);
      var C;
      var R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        var cell = sheet[_xlsx.default.utils.encode_cell({
          c: C,
          r: R
        })];
        /* find the cell in the first row */
        var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = _xlsx.default.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel: function isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDoctor = createDoctor;
exports.deleteDoctor = deleteDoctor;
exports.getDoctor = getDoctor;
exports.getDoctorCommission = getDoctorCommission;
exports.getDoctorList = getDoctorList;
exports.getFilter = getFilter;
exports.updateDoctor = updateDoctor;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDoctorList(query) {
  return (0, _request.default)({
    url: '/system/doctor/',
    method: 'get',
    params: query
  });
}
function getDoctor(id) {
  return (0, _request.default)({
    url: "/system/doctor/".concat(id, "/"),
    method: 'get'
  });
}
function createDoctor(data) {
  return (0, _request.default)({
    url: '/system/doctor/',
    method: 'post',
    data: data
  });
}
function updateDoctor(id, data) {
  return (0, _request.default)({
    url: "/system/doctor/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDoctor(id, data) {
  return (0, _request.default)({
    url: "/system/doctor/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function getFilter(query) {
  return (0, _request.default)({
    url: '/system/doctor/filter/',
    method: 'get',
    params: query
  });
}
// export function changePassword(data) {
//   return request({
//     url: '/system/doctor/password/',
//     method: 'put',
//     data
//   })
// }

function getDoctorCommission(data) {
  return (0, _request.default)({
    url: '/system/doctorcommission/',
    method: 'post',
    data: data
  });
}
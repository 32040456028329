"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createProduct = createProduct;
exports.deleteProduct = deleteProduct;
exports.getProductList = getProductList;
exports.updateProduct = updateProduct;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getProductList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/products"),
    method: 'get',
    params: query
  });
}
function createProduct(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/products"),
    method: 'post',
    data: data
  });
}
function updateProduct(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/products/").concat(id),
    method: 'put',
    data: data
  });
}
function deleteProduct(id) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/products/").concat(id),
    method: 'delete'
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTreatmentPartQuantity = createTreatmentPartQuantity;
exports.getTreatmentPartQuantityAll = getTreatmentPartQuantityAll;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreatmentPartQuantityAll(query) {
  return (0, _request.default)({
    url: '/system/treatmentpartquantity/',
    method: 'get',
    params: query
  });
}
function createTreatmentPartQuantity(data) {
  return (0, _request.default)({
    url: '/system/treatmentpartquantity/',
    method: 'post',
    data: data
  });
}

// export function updateDiseaseClass(id, data) {
//   return request({
//     url: `/system/diseaseclass/${id}/`,
//     method: 'put',
//     data
//   })
// }
//
// export function deleteDiseaseClass(id, data) {
//   return request({
//     url: `/system/diseaseclass/${id}/`,
//     method: 'delete',
//     data
//   })
// }
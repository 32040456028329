"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _patient = require("@/api/patient");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      patientList: {
        count: 0
      },
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 20
      },
      sexType: {
        2: '男',
        3: '女'
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _patient.getPatientList)(this.listQuery).then(function (response) {
        if (response.data) {
          _this.patientList = response.data;
        }
        _this.listLoading = false;
      });
    }
  }
};
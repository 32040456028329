"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCommissionPrescre = createCommissionPrescre;
exports.deleteCommissionPrescre = deleteCommissionPrescre;
exports.getCommissionPrescre = getCommissionPrescre;
exports.getCommissionPrescreList = getCommissionPrescreList;
exports.updateCommissionPrescre = updateCommissionPrescre;
var _request = _interopRequireDefault(require("@/utils/request"));
// 开方佣金

function getCommissionPrescreList(query) {
  return (0, _request.default)({
    url: '/system/commissionprescre/',
    method: 'get',
    params: query
  });
}
function getCommissionPrescre(id) {
  return (0, _request.default)({
    url: "/system/commissionprescre/".concat(id, "/"),
    method: 'get'
  });
}
function createCommissionPrescre(data) {
  return (0, _request.default)({
    url: '/system/commissionprescre/',
    method: 'post',
    data: data
  });
}
function updateCommissionPrescre(id, data) {
  return (0, _request.default)({
    url: "/system/commissionprescre/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteCommissionPrescre(id, data) {
  return (0, _request.default)({
    url: "/system/commissionprescre/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _device_disc = require("@/api/device_disc");
var _apiRecord = require("@/api/_homeCloudPlatform/apiRecord/apiRecord");
var _xlsx = _interopRequireDefault(require("xlsx"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  computed: {
    handleText: function handleText() {
      var stringWithoutBackslashes = '';
      if (this.record.body) {
        stringWithoutBackslashes = this.record.body.replace(/\\/g, '');
      }
      return stringWithoutBackslashes;
    },
    handleRequestBody: function handleRequestBody() {
      var stringWithoutBackslashes = '';
      if (this.requestBody) {
        stringWithoutBackslashes = this.requestBody.replace(/\\/g, '');
      }
      return stringWithoutBackslashes;
    }
  },
  data: function data() {
    return {
      requestBody: '',
      record: [],
      date: '',
      dateTYpe: {
        'start_end_time': '',
        'end_end_time': ''
      },
      disc_status: '',
      centerDialogVisible: false,
      recordDiscList: {
        count: 0
      },
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 20,
        page_size_before: 20
      },
      count: 0,
      defaultOrdering: '-start_time',
      // 状态选项
      statusOptions: [
      // { key: 'GET', display_name: 'GET'},
      {
        key: 'POST',
        display_name: 'POST'
      }, {
        key: 'PUT',
        display_name: 'PUT'
      }, {
        key: 'PATCH',
        display_name: 'PATCH'
      }, {
        key: 'DELETE',
        display_name: 'DELETE'
      }]
      // sexType: {
      //   1: '未知',
      //   2: '男',
      //   3: '女'
      // }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _apiRecord.getSilkRequest)(this.listQuery).then(function (response) {
        if (response.count >= 0) {
          _this.count = response.count;
          _this.recordDiscList = response;
        }
        _this.listLoading = false;
      });
    },
    viewStatus: function viewStatus(scope) {
      var _this2 = this;
      // console.log(scope.row.telemetry)
      // console.log(scope.row.telemetry.replace("'","\""))
      var obj = {
        "request": scope.row.id
      };
      this.requestBody = scope.row.body;
      (0, _apiRecord.getSilkResponse)(obj).then(function (response) {
        if (response.status_code == 200) {
          // this.count = response.count
          _this2.record = response.results[0];
        }
        _this2.listLoading = false;
      });
      this.centerDialogVisible = true;
    },
    getDate: function getDate() {
      if (this.date != null) {
        this.listQuery.start_end_time = this.date[0];
        this.listQuery.end_end_time = this.date[1];
      } else {
        this.listQuery.start_end_time = '';
        this.listQuery.end_end_time = '';
      }
      this.handleFilter();
    },
    handleExport: function handleExport() {
      var _this3 = this;
      var excelData = [['ID', '控释盘ID', '设备编号', '通道号', '控释盘使用次数', '开始时间', '结束时间', '日志']];
      (0, _device_disc.getRecordDiscList)({
        page: 1,
        page_size: this.count
      }).then(function (response) {
        _this3.table = response.data.results;
        for (var i = 0; i < _this3.table.length; i++) {
          _this3.rowData = [_this3.table[i].id, _this3.table[i].disc, _this3.table[i].device_number, _this3.table[i].channel, _this3.table[i].count, _this3.table[i].begin_time, _this3.table[i].end_time, _this3.table[i].telemetry];
          excelData.push(_this3.rowData);
        }
        var ws = _xlsx.default.utils.aoa_to_sheet(excelData);
        var wb = _xlsx.default.utils.book_new();
        _xlsx.default.utils.book_append_sheet(wb, ws, 'disc');
        _xlsx.default.writeFile(wb, '控释盘调理纪录.xlsx');
      });
    },
    handleSort: function handleSort(column) {
      // 初始化处理
      if (!column) {
        this.listQuery.ordering = this.listQuery.ordering || this.defaultOrdering;
        return {
          prop: this.defaultOrdering.indexOf('-') !== -1 ? this.defaultOrdering.split('-')[1] : this.defaultOrdering,
          order: this.defaultOrdering.indexOf('-') !== -1 ? 'descending' : 'ascending'
        };
      }
      // 点击排序
      else {
        this.listQuery.page = 1;
        this.listQuery.ordering = column.order ? "".concat(column.order === 'descending' ? '-' : '').concat(column.prop) : this.defaultOrdering;
        this.getList();
      }
    }
  }
};
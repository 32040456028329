"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGoodscategory = createGoodscategory;
exports.deleteGoodscategory = deleteGoodscategory;
exports.getGoodscategoryList = getGoodscategoryList;
exports.updateGoodscategory = updateGoodscategory;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getGoodscategoryList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goodscategory"),
    method: 'get',
    params: query
  });
}
function createGoodscategory(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/goodscategory"),
    method: 'post',
    data: data
  });
}
function updateGoodscategory(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goodscategory/").concat(id),
    method: 'put',
    data: data
  });
}
function deleteGoodscategory(id) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goodscategory/").concat(id),
    method: 'delete'
  });
}
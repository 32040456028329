"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _org = require("@/api/org");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _group = require("@/api/_homeCloudPlatform/group/group.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      recordList: {
        count: 0
      },
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 20,
        treatment_device_type: 0
      },
      filterOrgText: '',
      treeLoding: false,
      orgData: [],
      customerRecordTypeObj: {
        true: '店铺',
        false: '外部引流'
      }
    };
  },
  watch: {
    filterOrgText: function filterOrgText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created: function created() {
    this.getList();
    this.getOrgAll();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetFilter: function resetFilter() {
      this.listQuery = {
        page: 1,
        page_size: 20,
        treatment_device_type: 0
      };
      this.getList();
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleOrgClick: function handleOrgClick(obj, node, vue) {
      this.listQuery.page = 1;
      this.listQuery.dept = obj.id;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _group.getStoreTreatmentRecord)(this.listQuery).then(function (response) {
        if (response.status_code == 200) {
          _this.recordList = response;
          _this.recordList.results = _this.recordList.results.map(function (item) {
            if (item.record_type === 2) {
              item.huser_name = '---';
              item.treater_name = '---';
              item.order_no = '---';
              item.treatment_voucher_no = '---';
            }
            return item;
          });
        }
        _this.listLoading = false;
      });
    },
    getOrgAll: function getOrgAll() {
      var _this2 = this;
      this.treeLoding = true;
      (0, _org.getOrgAll)().then(function (response) {
        _this2.orgData = (0, _utils.genTree)(response.data);
        _this2.treeLoding = false;
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createNonZeroOrder = createNonZeroOrder;
exports.deleteNonZeroOrder = deleteNonZeroOrder;
exports.getNonZeroOrder = getNonZeroOrder;
exports.getNonZeroOrderList = getNonZeroOrderList;
exports.updateNonZeroOrder = updateNonZeroOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
function getNonZeroOrderList(query) {
  return (0, _request.default)({
    url: '/system/nonzeroorder/',
    method: 'get',
    params: query
  });
}
function getNonZeroOrder(id) {
  return (0, _request.default)({
    url: "/system/nonzeroorder/".concat(id, "/"),
    method: 'get'
  });
}
function createNonZeroOrder(data) {
  return (0, _request.default)({
    url: '/system/nonzeroorder/',
    method: 'post',
    data: data
  });
}
function updateNonZeroOrder(id, data) {
  return (0, _request.default)({
    url: "/system/nonzeroorder/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteNonZeroOrder(id, data) {
  return (0, _request.default)({
    url: "/system/nonzeroorder/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
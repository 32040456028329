"use strict";

var _interopRequireWildcard = require("/code/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
var _toConsumableArray2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _articleManage = require("@/api/_homeCloudPlatform/article/articleManage.js");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _Editor = _interopRequireDefault(require("@/components/Editor"));
var echarts = _interopRequireWildcard(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Vue from "vue";
// import ECharts from "vue-echarts";
// import "echarts/lib/chart/bar";
// import "echarts/lib/component/tooltip";
// import "echarts-gl";

var formModel = {
  labels: []
};

// Vue.component("v-chart", ECharts); // 注册为 global component
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    Editor: _Editor.default
  },
  computed: {
    dialogTitle: function dialogTitle() {
      var type = '';
      switch (this.dialogType) {
        case 'view':
          type = '检视';
          break;
        case 'add':
          type = '新增';
          break;
        case 'edit':
          type = '编辑';
          break;
      }
      return "".concat(type, "\u8C03\u7406\u53CD\u9988\u6A21\u677F");
    }
  },
  watch: {
    dialogType: {
      immediate: true,
      handler: function handler(val) {
        this.disabled = val !== 'edit' && val !== 'add';
      }
    }
  },
  mounted: function mounted() {
    // this.init()
    // this.handleViewReport()
  },
  data: function data() {
    return {
      defaulChecked: false,
      dashboardDialogVisible: false,
      option: {},
      formModel: formModel,
      disabled: false,
      tableData: {},
      listLoading: true,
      // 预设排序,依发布时间递减
      defaultOrdering: '-updated_at',
      listQuery: {
        page: 1,
        page_size: 20,
        page_size_before: 20
      },
      // dialogVisible: false,
      dialogType: 'view',
      previewImg: {},
      // 状态选项
      statusOptions: [{
        key: 'all',
        display_name: '全部',
        value: 'all'
      }, {
        key: 'true',
        display_name: '上架',
        value: true
      }, {
        key: 'false',
        display_name: '下架',
        value: !true
      }],
      // 类型
      typeOptions: [{
        key: 0,
        display_name: '全部'
      }, {
        key: 1,
        display_name: '标准'
      }, {
        key: 2,
        display_name: '体感'
      }, {
        key: 3,
        display_name: '印象'
      }],
      tagsList: [],
      // typeList: [],
      pageQuery: {
        pageSize: 10,
        pageNum: 1
      },
      // 查询参数
      searchParam: {},
      // 返回参数
      pageVO: {
        list: [],
        total: 0,
        pages: 0
      },
      markData: '',
      DelVisible: false,
      title: "新增",
      //表单新增/编辑
      updateTitle: '立即创建',
      dialogVisible: false,
      subDialogVisible: false,
      input: '',
      //体重
      value1: '',
      //时间选择器
      radio: '1',
      checkList: ['选中且禁用', '复选框 A'],
      ruleForm: {},
      contentForm: {
        content: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        // dynamicformTypeId: [
        //     { required: true, message: '请选择模板类型', trigger: 'change' }
        // ],
        remark: [{
          required: true,
          message: '请输入说明',
          trigger: 'blur'
        }, {
          max: 200,
          message: '字数限制200以内',
          trigger: 'blur'
        }]
      },
      contentrules: {
        title: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }
        // { min: 4, message: '长度至少4个字符', trigger: 'blur' }
        ],
        // type: [
        //     { required: true, message: '请选择模板类型', trigger: 'change' }
        // ],
        require: [{
          required: true,
          message: '请选择是否必填',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '请输入选项',
          trigger: 'change'
        }]
      },
      questionList: []
    };
  },
  created: function created() {
    // this.handleSort()
    this.getList();
  },
  methods: {
    checkPermission: _permission.default,
    getList: function getList(e) {
      var _this = this;
      this.listLoading = true;
      if (e && this.listQuery.page_size_before != e.limit) {
        this.listQuery.page = 1;
        this.listQuery.page_size_before = e.limit;
      }
      (0, _articleManage.getTemplate)(this.handleQuery()).then(function (res) {
        _this.tableData = res || {};
        _this.listLoading = false;
      });
    },
    handleAdd: function handleAdd() {
      var _this2 = this;
      this.ruleForm = JSON.parse(JSON.stringify(formModel));
      // this.handleInitData()
      this.dialogType = 'add';
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.questionList = [];
        _this2.$refs['Form'].clearValidate();
      });
    },
    handleView: function handleView(scope) {
      var _this3 = this;
      // let itemObj = JSON.parse('{"name":"11111","dynamicformTypeId":0,"remark":"2222","fields":[{"content":[{"content":"4444","isShow":true}],"title":"4444","type":"radio","require":true,"id":1696472885212},{"content":[{"content":"5555","isShow":true},{"content":"6666","isShow":true}],"title":"5555","type":"checkbox","require":true,"id":1696473098686},{"content":[],"title":"7777","type":"input","require":true,"id":1696473109056},{"content":[],"title":"8888","type":"datetime","require":true,"id":1696473117109}]}')
      // this.ruleForm = itemObj
      this.ruleForm = JSON.parse(JSON.stringify(scope.row));
      // this.handleInitData()
      this.questionList = this.ruleForm.questions;
      this.dialogType = 'view';
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['Form'].clearValidate();
      });
    },
    handleRowEdit: function handleRowEdit(scope) {
      var _this4 = this;
      var obj = {};
      obj = JSON.stringify(scope.row);
      var quesionsArray = scope.row.questions;
      this.ruleForm = JSON.parse(JSON.stringify(scope.row));
      if (quesionsArray && quesionsArray.length > 0) {
        quesionsArray.forEach(function (element) {
          if (!Array.isArray(element.content)) {
            element.content = _this4.fixAndParseString(element.content);
          }
        });
      }
      // console.log('this.quesionsArray:'+ JSON.stringify(quesionsArray))
      this.questionList = quesionsArray ? quesionsArray : [];
      this.dialogType = 'edit';
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['Form'].clearValidate();
      });
    },
    // 初始化资料处理
    // handleInitData() {
    //   this.previewImg = {
    //     image: this.formModel.image_url
    //   }
    //   if(this.formModel.labels.length){
    //     let arr = []
    //     this.formModel.labels.forEach(item=>{
    //       arr.push(item.name)
    //     })
    //     this.formModel.labels = arr
    //   }
    // },
    // async confirm(form) {
    //   this.$refs[form].validate(valid => {
    //     if (valid) {
    //       const isEdit = this.dialogType === 'edit'
    //       if (isEdit) {
    //         updateNews(this.formModel.id, this.handleFormData()).then(res => {
    //           if (res.status_code !== 200) {
    //             return this.errorHandle(res)
    //           }
    //           this.getList()
    //           this.dialogVisible = false
    //           this.$message({
    //             message: '编辑成功',
    //             type: 'success'
    //           })
    //         })
    //       } else if (this.dialogType === 'add') {
    //         createNews(this.handleFormData()).then(res => {
    //           if (res.status_code !== 200) {
    //             return this.errorHandle(res)
    //           }
    //           this.getList()
    //           this.dialogVisible = false
    //           this.$message({
    //             message: '新增成功',
    //             type: 'success'
    //           })
    //         })
    //       }
    //     } else {
    //       return false
    //     }
    //   })
    // },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetFilter: function resetFilter() {
      this.listQuery = {
        page: 1,
        page_size: this.listQuery.page_size
      };
      this.getList();
    },
    handleQuery: function handleQuery() {
      var query = (0, _objectSpread2.default)({}, this.listQuery);
      // if (query.label_id === '' || query.label_id === 'all') {
      //   query.label_id = (() => { return })()
      // }
      if (query.template_type == '0') {
        query.template_type = function () {
          return;
        }();
      }
      return query;
    },
    // handleFormData() {
    //   let arr = []
    //   this.formModel.labels.forEach( item => {
    //     arr.push({name:item})
    //   })
    //   if(this.previewImg.path) {
    //     this.formModel.image = this.previewImg.path
    //   }
    //   // this.formModel['content'] = this.$refs.editor.handlehtml(this.formModel['content'])
    //   let params = {
    //     ...this.formModel,
    //     labels: arr,
    //     status:(()=>{
    //       if(this.dialogType === 'edit') return this.formModel.status
    //       return
    //     })()
    //   }
    //   return params
    // },
    handleSort: function handleSort(column) {
      // 初始化处理
      if (!column) {
        this.listQuery.ordering = this.listQuery.ordering || this.defaultOrdering;
        return {
          prop: this.defaultOrdering.indexOf('-') !== -1 ? this.defaultOrdering.split('-')[1] : this.defaultOrdering,
          order: this.defaultOrdering.indexOf('-') !== -1 ? 'descending' : 'ascending'
        };
      }
      // 点击排序
      else {
        this.listQuery.page = 1;
        this.listQuery.ordering = column.order ? "".concat(column.order === 'descending' ? '-' : '').concat(column.prop) : this.defaultOrdering;
        this.getList();
      }
    },
    //删除内容
    deleteItem: function deleteItem(index) {
      this.questionList.splice(index, 1);
    },
    /* 编辑题目的选项 */handleEdit: function handleEdit(index, row) {
      row.isShow = false;
    },
    /* 保存题目的选项 */hold: function hold(index, row) {
      row.isShow = true;
    },
    /* 删除 */handleDelete: function handleDelete(index, row) {
      this.contentForm.content.splice(index, 1);
    },
    /* 添加新的一行 (默认是可编辑状态)*/addLine: function addLine() {
      var obj = {
        content: "",
        isShow: false,
        isRepulsion: false
      };
      this.contentForm.content.push(Object.assign({}, obj));
    },
    getTypePage: function getTypePage() {
      var _this5 = this;
      api.typePage((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageQuery), this.searchParam)).then(function (res) {
        _this5.pageVO = res;
        _this5.typeList = res.list;
        // console.log(this.typeList, '模板类型列表')
      });
    },
    //监听选择框的值
    getMarkChange: function getMarkChange(value) {
      this.markData = value;
    },
    // 上移
    moveUp: function moveUp(index) {
      var questionList = this.questionList;
      questionList.splice.apply(questionList, [index - 1, 1].concat((0, _toConsumableArray2.default)(questionList.splice(index, 1, questionList[index - 1]))));
    },
    // 下移
    moveDown: function moveDown(index) {
      var questionList = this.questionList;
      questionList.splice.apply(questionList, [index, 1].concat((0, _toConsumableArray2.default)(questionList.splice(index + 1, 1, questionList[index]))));
    },
    //编辑题目
    editContent: function editContent(item) {
      this.contentForm = (0, _objectSpread2.default)({}, item);
      if (this.contentForm.require == true) {
        this.contentForm.require = '是';
      } else {
        this.contentForm.require = '否';
      }
      // console.log(this.contentForm, '点击编辑查看当前表单')
      this.dialogVisible = true;
    },
    dataFormSubmit: function dataFormSubmit() {
      var _this6 = this;
      this.$refs.Form.validate(function (valid) {
        if (valid) {
          var isEdit = _this6.dialogType === 'edit';
          _this6.ruleForm.questions = _this6.questionList;
          // console.log(JSON.stringify(this.ruleForm))
          // return false
          // this.ruleForm.forEach((e,index) => console.log((e + 1),index))

          _this6.ruleForm.questions.forEach(function (item, i) {
            item.sort = i + 1;
          });
          if (isEdit) {
            (0, _articleManage.updateTemplate)(_this6.ruleForm.id, _this6.ruleForm).then(function (res) {
              if (res.status_code !== 200) {
                return _this6.errorHandle(res);
              }
              _this6.getList();
              _this6.dialogVisible = false;
              _this6.$message({
                message: '编辑成功',
                type: 'success'
              });
            });
          } else if (_this6.dialogType === 'add') {
            (0, _articleManage.createTemplate)(_this6.ruleForm).then(function (res) {
              if (res.status_code !== 200) {
                return _this6.errorHandle(res);
              }
              _this6.getList();
              _this6.dialogVisible = false;
              _this6.$message({
                message: '新增成功',
                type: 'success'
              });
            });
          }
          // console.log(JSON.stringify(this.ruleForm))
          // return false;

          // const request = this.$route.query.id ? api.update(this.ruleForm) : api.save(this.ruleForm)
          // request.then(data => {
          //     this.$message({
          //         message: this.$t('table.actionSuccess'),
          //         type: 'success',
          //         duration: 1500,
          //         onClose: () => {
          //             this.$refs.ruleForm.resetFields()
          //             this.$router.go(-1)
          //         }
          //     })
          // })
        } else {
          _this6.$nextTick(function () {
            var isError = document.getElementsByClassName('is-error');
            isError[0].scrollIntoView({
              block: 'center',
              behavior: 'smooth'
            });
          });
        }
      });
    },
    //添加题目
    submitContentForm: function submitContentForm() {
      var _this7 = this;
      this.$refs.contentRuleForm.validate(function (valid) {
        if (valid) {
          _this7.contentForm.require = _this7.contentForm.require == '是' ? true : false;
          var Obj = (0, _objectSpread2.default)({}, _this7.contentForm);
          // console.log('this.questionList:'+JSON.stringify(this.questionList))
          if (Obj.id) {
            _this7.questionList = _this7.questionList.map(function (ele) {
              return ele.id == Obj.id ? Obj : ele;
            });
          } else {
            Obj.id = Date.now();
            _this7.questionList = [].concat((0, _toConsumableArray2.default)(_this7.questionList), [Object.assign({}, Obj)]);
          }
          // console.log(JSON.stringify(this.questionList), '当前模板内容')
          _this7.subDialogVisible = false;
          _this7.$refs.contentRuleForm.resetFields();
        } else {
          return false;
        }
      });
    },
    //清空大表单
    cancelPage: function cancelPage() {
      this.$refs.ruleForm.resetFields();
      this.$router.go(-1);
    },
    //清空题目表单
    cancelDialog: function cancelDialog() {
      this.$refs.contentRuleForm.resetFields();
      this.subDialogVisible = false;
    },
    fixAndParseString: function fixAndParseString(inputString) {
      // console.log('inputString:'+JSON.stringify(inputString))
      if (inputString) {
        // 替换单引号为双引号，并将True替换为true
        var tempJsonString = inputString.replace(/'/g, '"').replace(/True/g, 'true');
        tempJsonString = tempJsonString.replace(/'/g, '"').replace(/False/g, 'false');
        // console.log('jsonString:'+JSON.stringify(tempJsonString))
        try {
          // 尝试解析JSON
          var jsonArray = JSON.parse(tempJsonString);
          console.log('jsonArray:' + JSON.stringify(jsonArray));
          return jsonArray;
        } catch (error) {
          console.error("无法解析JSON字符串:", error);
          return null;
        }
      }
    },
    cancelDialogDashboardDialog: function cancelDialogDashboardDialog() {
      this.dashboardDialogVisible = false;
    },
    // 柱状图
    initCharts: function initCharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'));

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '月销量'
        },
        // 提示框
        tooltip: {},
        // 图例
        legend: {
          data: ['销量']
        },
        // 表示x轴坐标
        xAxis: {
          data: ['oppo', 'vivo', 'iphone', '小米', '三星', '魅族']
        },
        // 表示y轴坐标
        yAxis: {},
        // 
        series: [{
          name: '销量',
          type: 'bar',
          data: [3500, 2200, 4500, 6500, 200, 3000]
        }]
      };
      var option2 = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [150, 230, 224, 218, 135, 147, 260],
          type: 'line'
        }]
      };
      myChart.setOption(option2);

      // 单选 - 圆饼图
      // 多选/文本/日期 - 柱状图
    },
    handleViewReport: function handleViewReport(scope) {
      console.log('scope.row:' + JSON.stringify(scope.row));
      var item = JSON.parse(JSON.stringify(scope.row));

      // return false;
      this.dashboardDialogVisible = true;
      (0, _articleManage.getTemplateAnswers)(item.id).then(function (res) {
        // console.log('res:'+JSON.stringify(res))
        setTimeout(function () {
          // 取得包含所有图表的容器
          var chartsContainer = document.getElementById('charts-container');
          chartsContainer.replaceChildren();
          // 使用回圈创建并初始化多个 ECharts 实例
          Object.keys(res).forEach(function (key) {
            if (key == "code") return;
            var item = res[key];
            var chartDiv = document.createElement('div');
            chartDiv.style.width = '650px';
            chartDiv.style.height = '300px';
            chartDiv.id = 'chart-' + key; // 唯一的 ID
            chartsContainer.appendChild(chartDiv);

            // 初始化 ECharts 实例
            var chart = echarts.init(chartDiv);

            // 设定图表选项
            var options = {
              title: {
                text: item.title,
                subtext: '统计次数'
              },
              xAxis: {
                name: '选项名称',
                data: item.answer //选项内容
              },
              yAxis: {},
              series: [{
                type: 'bar',
                data: item.answer_count
              }]
            };

            // 使用不同的选项渲染每个图表
            chart.setOption(options);
          });
          // for (var i = 0; i < numberOfCharts; i++) {

          // }
        }, 100);
        return false;
      });
    },
    cancelDashboardDialog: function cancelDashboardDialog() {
      this.dashboardDialogVisible = false;
    }
  }
};
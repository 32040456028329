"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/code/node_modules/core-js/modules/es6.array.iterator.js");
require("/code/node_modules/core-js/modules/es6.promise.js");
require("/code/node_modules/core-js/modules/es6.object.assign.js");
require("/code/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _index3 = _interopRequireDefault(require("@/directive/el-table/index"));
var _common = require("@/directive/common");
// A modern alternative to CSS resets

// import locale from 'element-ui/lib/locale/lang/en' // lang i18n 

// global css

// import echarts from 'echarts' 
// import 'echarts/lib/chart/line'

// icon 
// permission control

_vue.default.use(_index3.default);
_vue.default.use(_common.install);

// Vue.component( 'v-chart' ,  VueECharts ) 
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}

// 全局修改默认配置，点击空白处不能关闭弹窗
_elementUi.default.Dialog.props.closeOnClickModal.default = false;
// 全局修改默认配置，按下ESC不能关闭弹窗
_elementUi.default.Dialog.props.closeOnPressEscape.default = false;

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default, {
  size: 'medium'
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      statusOptions: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '待发货'
      }, {
        value: 3,
        label: '拒绝'
      }, {
        value: 4,
        label: '完成'
      }],
      attrOptions: [{
        value: 1,
        label: '购买'
      }, {
        value: 2,
        label: '赠送'
      }, {
        value: 3,
        label: '试用机'
      }, {
        value: 4,
        label: '其它'
      }]
    };
  }
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCityAll = getCityAll;
exports.getCityList = getCityList;
exports.getDistrictAll = getDistrictAll;
exports.getDistrictList = getDistrictList;
exports.getProvinceAll = getProvinceAll;
var _request = _interopRequireDefault(require("@/utils/request"));
function getProvinceAll() {
  return (0, _request.default)({
    url: '/system/province/',
    method: 'get'
  });
}
function getCityList(query) {
  return (0, _request.default)({
    url: '/system/city/',
    method: 'get',
    params: query
  });
}
function getCityAll() {
  return (0, _request.default)({
    url: '/system/city/',
    method: 'get'
  });
}
function getDistrictAll() {
  return (0, _request.default)({
    url: '/system/district/',
    method: 'get'
  });
}
function getDistrictList(query) {
  return (0, _request.default)({
    url: '/system/district/',
    method: 'get',
    params: query
  });
}
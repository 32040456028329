"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalTreatmentPartSaleQuantity = approvalTreatmentPartSaleQuantity;
exports.createTreatmentPartSaleQuantity = createTreatmentPartSaleQuantity;
exports.deleteTreatmentPartSaleQuantity = deleteTreatmentPartSaleQuantity;
exports.getTreatmentPartSaleQuantity = getTreatmentPartSaleQuantity;
exports.shipmentTreatmentPartSaleQuantity = shipmentTreatmentPartSaleQuantity;
exports.updateTreatmentPartSaleQuantity = updateTreatmentPartSaleQuantity;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreatmentPartSaleQuantity(query) {
  return (0, _request.default)({
    url: '/system/treatmentpartsalequantity/',
    method: 'get',
    params: query
  });
}
function createTreatmentPartSaleQuantity(data) {
  return (0, _request.default)({
    url: '/system/treatmentpartsalequantity/',
    method: 'post',
    data: data
  });
}
function updateTreatmentPartSaleQuantity(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartsalequantity/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteTreatmentPartSaleQuantity(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartsalequantity/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalTreatmentPartSaleQuantity(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartsalequantity/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
function shipmentTreatmentPartSaleQuantity(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartsalequantity/".concat(id, "/shipment/"),
    method: 'patch',
    data: data
  });
}
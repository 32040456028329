"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOta = createOta;
exports.getOtaList = getOtaList;
exports.getSilkRequest = getSilkRequest;
exports.getSilkResponse = getSilkResponse;
exports.updateOta = updateOta;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getOtaList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/ota"),
    method: 'get',
    params: query
  });
}
function createOta(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/ota"),
    method: 'post',
    data: data
  });
}
function updateOta(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/ota/").concat(id),
    method: 'patch',
    data: data
  });
}
function getSilkRequest(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/silk/request"),
    method: 'get',
    params: query
  });
}
function getSilkResponse(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/silk/response"),
    method: 'get',
    params: query
  });
}
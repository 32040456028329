"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlanDeviceList = getPlanDeviceList;
exports.getTreatmentRecordsList = getTreatmentRecordsList;
exports.getUsersList = getUsersList;
exports.getUsersQuota = getUsersQuota;
exports.postUsersQuota = postUsersQuota;
exports.updateUser = updateUser;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getUsersList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/users"),
    method: 'get',
    params: query
  });
}
function getPlanDeviceList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/plan/device"),
    method: 'get',
    params: query
  });
}
function updateUser(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/user/").concat(id),
    method: 'put',
    data: data
  });
}
function getTreatmentRecordsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/user/treatment_record"),
    method: 'get',
    params: query
  });
}
function getUsersQuota(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/users/user_quota"),
    method: 'get',
    params: query
  });
}
function postUsersQuota(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/users/user_quota"),
    method: 'post',
    data: data
  });
}
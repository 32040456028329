"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGoodsOnlineOrdersDisc = createGoodsOnlineOrdersDisc;
exports.createOfflinePay = createOfflinePay;
exports.createReturnOnlineOrder = createReturnOnlineOrder;
exports.deleteGoodsOnlineOrdersDisc = deleteGoodsOnlineOrdersDisc;
exports.getGoodsList = getGoodsList;
exports.getGoodsOnlineOrders = getGoodsOnlineOrders;
exports.getGoodsSum = getGoodsSum;
exports.getOnlineOrdersList = getOnlineOrdersList;
exports.getOnlineOrdersVoucherList = getOnlineOrdersVoucherList;
exports.getWorkingLog = getWorkingLog;
exports.updateOfflinePay = updateOfflinePay;
exports.updateOnlineOrder = updateOnlineOrder;
exports.updateOnlineOrderStatus = updateOnlineOrderStatus;
exports.updateReturnOnlineOrder = updateReturnOnlineOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getOnlineOrdersList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/online_orders"),
    method: 'get',
    params: query
  });
}
function updateOnlineOrder(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/online_orders/").concat(id),
    method: 'put',
    data: data
  });
}
// 編輯退貨信息
function updateReturnOnlineOrder(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/return_online_orders/").concat(id),
    method: 'put',
    data: data
  });
}
// 申請退貨
function createReturnOnlineOrder(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/return_online_orders"),
    method: 'post',
    data: data
  });
}
// 修改訂單狀態
function updateOnlineOrderStatus(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/online_orders/").concat(id, "/status"),
    method: 'patch',
    data: data
  });
}

// 申请变更已付款
function createOfflinePay(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/offline_pay"),
    method: 'post',
    data: data
  });
}

// 审核变更已付款
function updateOfflinePay(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/offline_pay/").concat(id),
    method: 'put',
    data: data
  });
}
//取得操作紀錄
function getWorkingLog(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/workinglog"),
    method: 'get',
    params: query
  });
}
// 單盤調換處理-新增
function createGoodsOnlineOrdersDisc(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_online_orders/").concat(id, "/disc"),
    method: 'patch',
    data: data
  });
}
// 單盤調換處理-刪除
function deleteGoodsOnlineOrdersDisc(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_online_orders/").concat(id, "/disc"),
    method: 'delete',
    data: data
  });
}

// 到店產品包調理碼信息
function getOnlineOrdersVoucherList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_online_orders/voucher"),
    method: 'get',
    params: query
  });
}

// 產品包信息
function getGoodsOnlineOrders(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_online_orders"),
    method: 'get',
    params: query
  });
}

// 訂單商品匯總信息
function getGoodsSum(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/online_orders/goods_sum"),
    method: 'get',
    params: query
  });
}

// 產品包信息（Light減肥版）
function getGoodsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_online_orders/console"),
    method: 'get',
    params: query
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.install = void 0;
var install = exports.install = function install(Vue) {
  // 防止連按
  Vue.directive('preventReClick', {
    inserted: function inserted(el, binding) {
      el.addEventListener('click', function () {
        if (!el.disabled) {
          el.disabled = true;
          setTimeout(function () {
            el.disabled = false;
          }, binding.value || 1500);
        }
      });
    }
  });
};
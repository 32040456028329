"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createBasicPreparations = createBasicPreparations;
exports.deleteBasicPreparations = deleteBasicPreparations;
exports.getBasicPreparationsAll = getBasicPreparationsAll;
exports.updateBasicPreparations = updateBasicPreparations;
var _request = _interopRequireDefault(require("@/utils/request"));
function getBasicPreparationsAll() {
  return (0, _request.default)({
    url: '/system/basicpreparations/',
    method: 'get'
  });
}
function createBasicPreparations(data) {
  return (0, _request.default)({
    url: '/system/basicpreparations/',
    method: 'post',
    data: data
  });
}
function updateBasicPreparations(id, data) {
  return (0, _request.default)({
    url: "/system/basicpreparations/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteBasicPreparations(id, data) {
  return (0, _request.default)({
    url: "/system/basicpreparations/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
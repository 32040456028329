"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalDeviceSalesOrder = approvalDeviceSalesOrder;
exports.createDeviceSalesOrder = createDeviceSalesOrder;
exports.deleteDevice = deleteDevice;
exports.deleteDeviceSalesOrder = deleteDeviceSalesOrder;
exports.getDeviceReportList = getDeviceReportList;
exports.getDeviceSalesOrder = getDeviceSalesOrder;
exports.getDeviceSalesOrderAll = getDeviceSalesOrderAll;
exports.shipmentDeviceSalesOrder = shipmentDeviceSalesOrder;
exports.updateDeviceSalesOrder = updateDeviceSalesOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDeviceSalesOrderAll(query) {
  return (0, _request.default)({
    url: '/system/devicesalesorder/',
    method: 'get',
    params: query
  });
}
function getDeviceSalesOrder(id) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id),
    method: 'get'
  });
}
function createDeviceSalesOrder(data) {
  return (0, _request.default)({
    url: '/system/devicesalesorder/',
    method: 'post',
    data: data
  });
}
function updateDeviceSalesOrder(id, data) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDeviceSalesOrder(id, data) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalDeviceSalesOrder(id, data) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
function shipmentDeviceSalesOrder(id, data) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id, "/shipment/"),
    method: 'patch',
    data: data
  });
}
function deleteDevice(id, data) {
  return (0, _request.default)({
    url: "/system/devicesalesorder/".concat(id, "/delete-device/"),
    method: 'patch',
    data: data
  });
}
function getDeviceReportList(query) {
  return (0, _request.default)({
    url: '/system/devicesalesordereport/',
    method: 'get',
    params: query
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSystemConfigurationsList = getSystemConfigurationsList;
exports.patchSystemConfigurations = patchSystemConfigurations;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getSystemConfigurationsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/system_configurations"),
    method: 'get',
    params: query
  });
}
function patchSystemConfigurations(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/system_configurations/").concat(id),
    method: 'patch',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createUsageMethod = createUsageMethod;
exports.deleteUsageMethod = deleteUsageMethod;
exports.getUsageMethodAll = getUsageMethodAll;
exports.updateUsageMethod = updateUsageMethod;
var _request = _interopRequireDefault(require("@/utils/request"));
function getUsageMethodAll() {
  return (0, _request.default)({
    url: '/system/usagemethod/',
    method: 'get'
  });
}
function createUsageMethod(data) {
  return (0, _request.default)({
    url: '/system/usagemethod/',
    method: 'post',
    data: data
  });
}
function updateUsageMethod(id, data) {
  return (0, _request.default)({
    url: "/system/usagemethod/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteUsageMethod(id, data) {
  return (0, _request.default)({
    url: "/system/usagemethod/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
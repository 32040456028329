"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createZeroOrder = createZeroOrder;
exports.deleteZeroOrder = deleteZeroOrder;
exports.getZeroOrder = getZeroOrder;
exports.getZeroOrderList = getZeroOrderList;
exports.updateZeroOrder = updateZeroOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
function getZeroOrderList(query) {
  return (0, _request.default)({
    url: '/system/zeroorder/',
    method: 'get',
    params: query
  });
}
function getZeroOrder(id) {
  return (0, _request.default)({
    url: "/system/zeroorder/".concat(id, "/"),
    method: 'get'
  });
}
function createZeroOrder(data) {
  return (0, _request.default)({
    url: '/system/zeroorder/',
    method: 'post',
    data: data
  });
}
function updateZeroOrder(id, data) {
  return (0, _request.default)({
    url: "/system/zeroorder/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteZeroOrder(id, data) {
  return (0, _request.default)({
    url: "/system/zeroorder/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _components = require("@/layout/components");
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    perms: ['admin','editor']    control the page perms (you can set multiple perms)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all perms can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/changepassword',
  component: _layout.default,
  redirect: '/changepassword',
  name: 'ChangePW',
  meta: {
    title: '修改密码',
    icon: 'tree'
  },
  hidden: true,
  children: [{
    path: '',
    name: 'ChangePassword',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/changepassword'));
      });
    },
    meta: {
      title: '修改密码',
      noCache: true,
      icon: ''
    },
    hidden: true
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user perms
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/system',
  component: _layout.default,
  redirect: '/system/user',
  name: 'System',
  alwaysShow: true,
  meta: {
    title: '系统管理',
    icon: 'system',
    perms: ['system_manage']
  },
  children: [{
    path: 'personnel',
    component: _components.AppMain,
    redirect: '/system/personnel/doctormanage',
    name: 'Personnel',
    alwaysShow: true,
    meta: {
      title: '用户管理',
      icon: 'person',
      perms: ['person_manage']
    },
    children: [{
      path: 'doctormanage',
      name: 'DoctorManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personnel/index.vue'));
        });
      },
      meta: {
        title: 'TTS专家管理',
        icon: 'doctor',
        perms: ['doctor_manage']
      }
    }, {
      path: 'sign',
      name: 'Sign',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personnel/sign.vue'));
        });
      },
      meta: {
        title: 'TTS专家注册审批',
        icon: 'documentation',
        perms: ['sign_manage']
      }
    }, {
      path: 'therapist',
      name: 'Therapist',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personnel/therapist.vue'));
        });
      },
      meta: {
        title: 'TTS操作师管理',
        icon: 'treater',
        perms: ['therapist_manage']
      }
    }, {
      path: 'sign2',
      name: 'Sign2',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personnel/sign2.vue'));
        });
      },
      meta: {
        title: 'TTS操作师注册审批',
        icon: 'documentation',
        perms: ['sign2_manage']
      }
    }, {
      path: 'patient',
      name: 'Patient',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/personnel/patient.vue'));
        });
      },
      meta: {
        title: 'C端客户管理',
        icon: 'patient',
        perms: ['patient_manage']
      }
    }]
  }, {
    path: 'user',
    name: 'User',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/user.vue'));
      });
    },
    meta: {
      title: '人员管理',
      icon: 'user',
      perms: ['user_manage']
    }
  }, {
    path: 'organization',
    name: 'Organization',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/organization'));
      });
    },
    meta: {
      title: '部门管理',
      icon: 'tree',
      perms: ['org_manage']
    }
  }, {
    path: 'role',
    name: 'Role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/role'));
      });
    },
    meta: {
      title: '角色管理',
      icon: 'lock',
      perms: ['role_manage']
    }
  },
  // {
  //   path: 'position',
  //   name: 'Position',
  //   component: () => import('@/views/system/position'),
  //   meta: { title: '岗位管理', icon: 'position', perms: ['position_manage'] }
  // },
  // {
  //   path: 'dict',
  //   name: 'Dict',
  //   component: () => import('@/views/system/dict'),
  //   meta: { title: '数据字典', icon: 'example', perms: ['dict_manage'] }
  // },
  {
    path: 'file',
    name: 'File',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/file'));
      });
    },
    meta: {
      title: '文件库',
      icon: 'documentation',
      perms: ['file_room']
    }
  }
  // {
  //   path: 'task',
  //   name: 'Task',
  //   component: () => import('@/views/system/task'),
  //   meta: { title: '定时任务', icon: 'list', perms: ['ptask_manage'] }
  // }
  ]
}, {
  path: '/basic',
  component: _layout.default,
  redirect: '/basic/diseaseclass',
  name: 'Basic',
  alwaysShow: true,
  meta: {
    title: '制剂管理',
    icon: 'disease',
    perms: ['disease_manage']
  },
  children: [{
    path: 'diseaseclass',
    name: 'DiseaseClass',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/diseaseclass.vue'));
      });
    },
    meta: {
      title: '专家工作室分类',
      icon: 'class',
      perms: ['class_manage']
    }
  }, {
    path: 'diseaselist',
    name: 'Diseaselist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/diseaselist.vue'));
      });
    },
    meta: {
      title: '疾病设置',
      icon: 'classedit',
      perms: ['diseaselist_manage']
    }
  }, {
    path: 'usage',
    name: 'Usage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/usage.vue'));
      });
    },
    meta: {
      title: '常用穴位',
      icon: 'method',
      perms: ['usage_manage']
    }
  }, {
    path: 'basicpreparations',
    name: 'BasicPreparations',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/basicpreparations.vue'));
      });
    },
    meta: {
      title: '基础制剂',
      icon: 'basicpreparations',
      perms: ['basicpreparations_manage']
    }
  }, {
    path: 'preparations',
    name: 'Preparations',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/preparations.vue'));
      });
    },
    meta: {
      title: '成品制剂',
      icon: 'preparations',
      perms: ['preparations_manage']
    }
  }, {
    path: 'plan',
    name: 'Plan',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basicconfig/plan.vue'));
      });
    },
    meta: {
      title: 'C端方案',
      icon: 'plan',
      perms: ['plan_manage']
    }
  }]
}, {
  path: '/product',
  component: _layout.default,
  redirect: '/product/purchase/device',
  name: 'Product',
  alwaysShow: true,
  meta: {
    title: '产品管理',
    icon: 'device2',
    perms: ['products_manage']
  },
  children: [
  // {
  //   path: 'device',
  //   component: AppMain,
  //   redirect: '/device/manage',
  //   name: 'Device',
  //   meta: { title: '设备管理', icon: 'device2', perms: ['devices_manage'] },
  //   children: [
  //     {
  //       path: 'manage',
  //       name: 'Manage',
  //       component: () => import('@/views/device/index.vue'),
  //       meta: { title: '设备详情', icon: 'device', perms: ['device_manage'] }
  //     },
  //     {
  //       path: 'error',
  //       name: 'Error',
  //       component: () => import('@/views/device/error.vue'),
  //       meta: { title: '设备异常', icon: 'device_error', perms: ['error_manage'] }
  //     },
  //     {
  //       path: 'disc',
  //       name: 'Disc',
  //       component: () => import('@/views/device/disc.vue'),
  //       meta: { title: '控释盘状态', icon: 'method', perms: ['disc_manage'] }
  //     }
  //   ]
  // },
  {
    path: 'purchase',
    component: _components.AppMain,
    redirect: '/product/purchase/device',
    name: 'Purchase',
    alwaysShow: true,
    meta: {
      title: '采购管理',
      icon: 'buy-svgrepo-com',
      perms: ['purchase_manage']
    },
    children: [{
      path: 'device',
      name: 'Manage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchase/index.vue'));
        });
      },
      meta: {
        title: '设备',
        icon: 'device',
        perms: ['device_manage']
      }
    }, {
      path: 'treatmentpartquantity',
      name: 'TreatmentPartQuantity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/purchase/treatmentpartquantity.vue'));
        });
      },
      meta: {
        title: '穴位数',
        icon: 'device_error',
        perms: ['treatmentpartquantity_manage']
      }
    }]
  }, {
    path: 'sale',
    component: _components.AppMain,
    redirect: '/product/sale/device',
    name: 'Sale',
    alwaysShow: true,
    meta: {
      title: '销售管理',
      icon: 'sale-discount',
      perms: ['sale_manage']
    },
    children: [{
      path: 'device',
      name: 'Manage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/index.vue'));
        });
      },
      meta: {
        title: '设备',
        icon: 'device',
        perms: ['devicesale_manage']
      }
    }, {
      path: 'treatmentpartsalequantity',
      name: 'TreatmentPartSaleQuantity',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/treatmentpartsalequantity.vue'));
        });
      },
      meta: {
        title: '穴位数',
        icon: 'device_error',
        perms: ['treatmentpartsalequantity_manage']
      }
    },
    //integrate device and treatmentpartsalequantity into offline sales list
    {
      path: 'offlineorder',
      name: 'offlineorder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/offlineorder.vue'));
        });
      },
      meta: {
        title: '线下订单',
        icon: 'method',
        perms: ['offlineordersale_manage']
      }
    }, {
      path: 'order',
      name: 'order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/order.vue'));
        });
      },
      meta: {
        title: '线上订单',
        icon: 'method',
        perms: ['ordersale_manage']
      }
    }, {
      path: 'ordershipment',
      name: 'ordershipment',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/ordershipment.vue'));
        });
      },
      meta: {
        title: '待发货分润销售单',
        icon: 'method',
        perms: ['ordersale_ship_manage']
      }
    }, {
      path: 'zeroorder',
      name: 'zeroorder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/zeroorder.vue'));
        });
      },
      meta: {
        title: '待确认0元订单',
        icon: 'method',
        perms: ['ordersale_zeroorder_manage']
      }
    }, {
      path: 'treatmentpartadjust',
      name: 'TreatmentPartAdjust',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sale/treatmentpartadjust.vue'));
        });
      },
      meta: {
        title: '订单穴位数调整',
        icon: 'device_error',
        perms: ['treatmentpartadjust_manage']
      }
    }]
  }, {
    path: 'inventory',
    component: _components.AppMain,
    redirect: '/product/inventory/query',
    name: 'Inventory',
    alwaysShow: true,
    meta: {
      title: '库存管理',
      icon: 'inventory',
      perms: ['inventory_manage']
    },
    children: [{
      path: 'query',
      name: 'Query',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/inventory/index'));
        });
      },
      meta: {
        title: '库存查询',
        icon: 'search-stock',
        perms: ['queryinventory_manage']
      }
    }]
  }, {
    path: 'aftersale',
    component: _components.AppMain,
    redirect: '/product/aftersale/return/device',
    name: 'AfterSale',
    alwaysShow: true,
    meta: {
      title: '售后管理',
      icon: 'service-svgrepo-com',
      perms: ['aftersale_manage']
    },
    children: [{
      path: 'return',
      name: 'Return',
      component: _components.AppMain,
      redirect: '/product/aftersale/return/device',
      meta: {
        title: '销售退换货管理',
        icon: 'device',
        perms: ['return_manage']
      },
      children: [{
        path: 'device',
        name: 'Device',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/return/devicereturn.vue'));
          });
        },
        meta: {
          title: '设备',
          icon: 'device',
          perms: ['devicereturn_manage']
        }
      }, {
        path: 'treatmentpartreturn',
        name: 'TreatmentPartyreturn',
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/return/treatmentpartreturn.vue'));
          });
        },
        meta: {
          title: '穴位数',
          icon: 'method',
          perms: ['treatmentpartreturn_manage']
        }
      }]
    }
    // ,
    // {
    //   path: 'aftersaleservice',
    //   name: 'AfterSaleService',
    //   component: AppMain,
    //   redirect: '/aftersaleservice/manage',
    //   // meta: { title: '售后服务管理', icon: 'method', perms: ['aftersaleservice_manage'] },
    //   children: [
    //     {
    //       path: 'aftersaleservice',
    //       name: 'AfterSaleService',
    //       // component: () => import('@/views/system/perm'),
    //       meta: { title: '售后服务管理', icon: 'example', perms: ['aftersaleservice_manage'] }
    //     }
    //   ]
    // }
    ]
  }]
}, {
  path: '/therapy',
  component: _layout.default,
  redirect: '/therapy/order',
  name: 'Therapy',
  alwaysShow: true,
  meta: {
    title: '设备运行管理',
    icon: 'therapy',
    perms: ['systemop_manage']
  },
  children: [
  // {
  //   path: 'order',
  //   name: 'Order',
  //   component: () => import('@/views/therapy/order.vue'),
  //   meta: { title: '订单管理', icon: 'order', perms: ['order_manage'] }
  // },
  {
    path: 'record',
    name: 'Record',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/therapy/treatrecord.vue'));
      });
    },
    meta: {
      title: 'TTS操作记录',
      icon: 'treatmentrecord',
      perms: ['ttsoprecord_manage']
    }
  }, {
    path: 'recordHome',
    name: 'RecordHome',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/therapy/treatrecordHome.vue'));
      });
    },
    meta: {
      title: 'TTH操作记录',
      icon: 'treatmentrecord',
      perms: ['tthoprecord_manage']
    }
  }, {
    path: 'recordStore',
    name: 'RecordStore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/therapy/treatrecordStore.vue'));
      });
    },
    meta: {
      title: '店鋪操作记录',
      icon: 'treatmentrecord',
      perms: ['storeoprecord_manage']
    }
  }, {
    path: 'disc',
    name: 'Disc',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/device/disc.vue'));
      });
    },
    meta: {
      title: '控释盘状态',
      icon: 'method',
      perms: ['discstatus_manage']
    }
  }, {
    path: 'apiRecord',
    name: 'ApiRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/device/apiRecord.vue'));
      });
    },
    meta: {
      title: '到家API请求纪录',
      icon: 'treatmentrecord',
      perms: ['apirecord_manage']
    }
  }
  // {
  //   path: 'disccount',
  //   name: 'disccount',
  //   component: () => import('@/views/therapy/disccount.vue'),
  //   meta: { title: '盘次分配记录', icon: 'slice', perms: ['disccount_manage'] }
  // },
  // {
  //   path: 'commission',
  //   name: 'commission',
  //   component: () => import('@/views/therapy/commission.vue'),
  //   meta: { title: '佣金返利查询', icon: 'commission', perms: ['commission_manage'] }
  // }
  // {
  //   path: 'coupon',
  //   name: 'Coupon',
  //   component: () => import('@/views/therapy/coupon.vue'),
  //   meta: { title: '优惠券管理', icon: 'documentation', perms: ['coupon_manage'] }
  // },
  // {
  //   path: 'coupondetail',
  //   name: 'CouponDetail',
  //   component: () => import('@/views/therapy/coupondetail.vue'),
  //   meta: { title: '优惠券详情', icon: 'documentation', perms: ['coupondetail_manage'] },
  //   hidden: true
  // }
  ]
}, {
  path: '/financial',
  component: _layout.default,
  redirect: '/financial',
  name: 'Financial',
  alwaysShow: true,
  meta: {
    title: '财务管理',
    icon: 'financial',
    perms: ['financial_manage']
  },
  children: [{
    path: 'monthlyreport',
    redirect: '/financial/monthlyreport/device',
    name: 'MonthlyReport',
    alwaysShow: true,
    component: _components.AppMain,
    meta: {
      title: '月报表',
      icon: 'example',
      perms: ['monthly_report']
    },
    children: [{
      path: 'device',
      name: 'Device',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/index'));
        });
      },
      meta: {
        title: '线下设备订单报表',
        icon: 'device',
        perms: ['device_order_report']
      }
    }, {
      path: 'order',
      name: 'Order',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/order'));
        });
      },
      meta: {
        title: '线上处方报表',
        icon: 'method',
        perms: ['order_report']
      }
    }, {
      path: 'doctor',
      name: 'Doctor',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/doctor'));
        });
      },
      meta: {
        title: '医生分润报表',
        icon: 'doctor',
        perms: ['doctor_commission_report']
      }
    }, {
      path: 'profit',
      name: 'Profit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/profit'));
        });
      },
      meta: {
        title: '渠道分润报表',
        icon: 'documentation',
        perms: ['branch_commission_report']
      }
    }]
  }]
},
// {
//   path: '/',
//   component: Layout,
//   children: [
//     {
//       path: 'managequery',
//       name: 'ManageQuery',
//       // component: () => import('@/views/system/perm'),
//       meta: { title: '管理查询', icon: 'ui-search', perms: ['query_manage'] }
//     }
//   ]
// },
{
  path: '/externalmanagement',
  component: _layout.default,
  children: [{
    path: '',
    name: 'ExternalManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/externalmanagement'));
      });
    },
    meta: {
      title: '外部对接管理',
      icon: 'hospital',
      perms: ['externalmanagement_manage']
    }
  }]
}, {
  path: '/develop',
  component: _layout.default,
  redirect: '/develop/perm',
  name: 'Develop',
  meta: {
    title: '开发配置',
    icon: 'develop'
  },
  hidden: true,
  children: [{
    path: 'perm',
    name: 'Perm',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/perm'));
      });
    },
    meta: {
      title: '权限菜单',
      icon: 'example'
    }
  },
  // {
  //   path: 'form-gen-link',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://jakhuang.github.io/form-generator/',
  //       meta: { title: '表单设计器', icon: 'link', perms: ['dev_form_gen'] }
  //     }
  //   ]
  // },
  // {
  //   path: 'docs',
  //   component: Layout,
  //   children: [
  //     {
  //       path: process.env.VUE_APP_BASE_API + '/docs/',
  //       meta: { title: '接口文档', icon: 'link', perms: ['dev_docs'] }
  //     }
  //   ]
  // },
  // {
  //   path: 'swagger',
  //   component: Layout,
  //   children: [
  //     {
  //       path: process.env.VUE_APP_BASE_API + '/swagger/',
  //       meta: { title: 'Swagger文档', icon: 'link', perms: ['dev_docs'] }
  //     }
  //   ]
  // },
  {
    path: 'admin-link',
    component: _layout.default,
    children: [{
      path: process.env.VUE_APP_BASE_API + '/admin/',
      meta: {
        title: 'Django后台',
        icon: 'link'
      }
    }]
  }]
},
// 到家 、 商城
{
  path: '/memberManage',
  component: _layout.default,
  children: [{
    path: '',
    name: 'memberManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/home/memberManage'));
      });
    },
    meta: {
      title: '到家会员管理',
      icon: 'person',
      perms: ['homeMember_manage']
    }
  }]
}, {
  path: '/mall',
  redirect: '/mall/commodityManage',
  component: _layout.default,
  alwaysShow: true,
  meta: {
    title: '商城管理',
    icon: 'shopping',
    perms: ['mall_manage']
  },
  children: [{
    path: 'commodityManage',
    name: 'commodityManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/commodityManage'));
      });
    },
    meta: {
      title: 'C端商品管理',
      icon: 'list',
      perms: ['commodity_manage']
    }
  }, {
    path: 'orderManage',
    name: 'orderManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/orderManage'));
      });
    },
    meta: {
      title: 'C端订单管理',
      icon: 'order',
      perms: ['mallorder_manage']
    }
  },
  // {
  //   path: 'orderShopManage',
  //   name: 'orderShopManage',
  //   component: () => import('@/views/_homeCloudPlatform/mall/orderShopManage'),
  //   meta: { title: 'C端到店订单管理', icon: 'order', perms: ['mallorder_shop_manage'] }
  // },      
  {
    path: 'bGoodsManage',
    name: 'bGoodsManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/bGoodsManage'));
      });
    },
    meta: {
      title: 'B端商品管理',
      icon: 'list',
      perms: ['b_goods_manage']
    }
  }, {
    path: 'bMall0rderManage',
    name: 'bMall0rderManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/bMall0rderManage'));
      });
    },
    meta: {
      title: 'B端订单管理',
      icon: 'order',
      perms: ['b_mallorder_manage']
    }
  },
  // TODO
  // {
  //   path: 'offlineOrderManage',
  //   name: 'offlineOrderManage',
  //   component: () => import('@/views/_homeCloudPlatform/mall/offlineOrderManage'),
  //   meta: { title: '线下订单管理', icon: 'order', perms: ['mallOfflineOrder_manage'] }
  // },
  {
    path: 'commodityClassificationManage',
    name: 'commodityClassificationManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/commodityClassificationManage'));
      });
    },
    meta: {
      title: '商品分类管理',
      icon: 'tree-table',
      perms: ['commodityClassification_manage']
    }
  }, {
    path: 'productManage',
    name: 'productManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/mall/productManage'));
      });
    },
    meta: {
      title: '产品管理',
      icon: 'tree',
      perms: ['product_manage']
    }
  }]
},
//TODO
//机构店舖管理
{
  path: '/group',
  redirect: '/group/groupManage',
  component: _layout.default,
  alwaysShow: true,
  meta: {
    title: '机构店舖管理',
    icon: 'tree',
    perms: ['groupinfo_manage']
  },
  children: [{
    path: 'groupManage',
    name: 'groupManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/group/groupManage'));
      });
    },
    meta: {
      title: '合作机构管理',
      icon: 'hospital',
      perms: ['group_manage']
    }
  }, {
    path: 'storeManage',
    name: 'storeManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/group/storeManage'));
      });
    },
    meta: {
      title: '店铺管理',
      icon: 'tree-table',
      perms: ['store_manage']
    }
  }]
},
// {
//   path: '/groupManage',
//   component: Layout,
//   children: [
//     {
//       path: '',
//       name: 'groupManage',
//       component: () => import('@/views/_homeCloudPlatform/group/groupManage'),
//       meta: { title: '合作机构管理', icon: 'peoples', perms: ['group_manage'] }
//     }
//   ]
// },
{
  path: '/advertiseManage',
  component: _layout.default,
  children: [{
    path: '',
    name: 'advertiseManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/advertise/advertiseManage'));
      });
    },
    meta: {
      title: '广告管理',
      icon: 'eye-open',
      perms: ['advertise_manage']
    }
  }]
}, {
  path: '/parameterManage',
  // redirect: '/parameterManage',
  component: _layout.default,
  children: [{
    path: '',
    name: 'parameterManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/parameter/parameterManage'));
      });
    },
    meta: {
      title: '参数管理',
      icon: 'edit',
      perms: ['parameter_manage']
    }
  }]
}, {
  path: '/otaManage',
  component: _layout.default,
  children: [{
    path: '',
    name: 'otaManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/ota/otaManage'));
      });
    },
    meta: {
      title: '固件版本管理',
      icon: 'device2',
      perms: ['ota_manage']
    }
  }]
}, {
  path: '/article',
  redirect: '/article/caseShareManage',
  component: _layout.default,
  alwaysShow: true,
  meta: {
    title: '文章管理',
    icon: 'order',
    perms: ['article_manage']
  },
  children: [{
    path: 'caseShareManage',
    name: 'caseShareManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/article/caseShareManage'));
      });
    },
    meta: {
      title: '病例分享',
      icon: 'list',
      perms: ['caseShare_manage']
    }
  }, {
    path: 'newsManage',
    name: 'newsManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/article/newsManage'));
      });
    },
    meta: {
      title: '首页文章',
      icon: 'order',
      perms: ['news_manage']
    }
  }, {
    path: 'formsTemplate',
    name: 'formsTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/_homeCloudPlatform/article/formsTemplate'));
      });
    },
    meta: {
      title: '调理反馈模板',
      icon: 'order',
      perms: ['formsTemplate']
    }
  }
  // {
  //   path: 'newTemplate',
  //   name: 'newTemplate',
  //   component: () => import('@/views/_homeCloudPlatform/article/newTemplate'),
  //   meta: { title: '调理反馈模板', icon: 'order', perms: ['newTemplate'] }
  // }       
  ]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCommissionTreat = createCommissionTreat;
exports.deleteCommissionTreat = deleteCommissionTreat;
exports.getCommissionTreat = getCommissionTreat;
exports.getCommissionTreatList = getCommissionTreatList;
exports.updateCommissionTreat = updateCommissionTreat;
var _request = _interopRequireDefault(require("@/utils/request"));
// 调理佣金

function getCommissionTreatList(query) {
  return (0, _request.default)({
    url: '/system/commissiontreat/',
    method: 'get',
    params: query
  });
}
function getCommissionTreat(id) {
  return (0, _request.default)({
    url: "/system/commissiontreat/".concat(id, "/"),
    method: 'get'
  });
}
function createCommissionTreat(data) {
  return (0, _request.default)({
    url: '/system/commissiontreat/',
    method: 'post',
    data: data
  });
}
function updateCommissionTreat(id, data) {
  return (0, _request.default)({
    url: "/system/commissiontreat/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteCommissionTreat(id, data) {
  return (0, _request.default)({
    url: "/system/commissiontreat/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
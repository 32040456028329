"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalTreatmentPartReturn = approvalTreatmentPartReturn;
exports.createTreatmentPartReturn = createTreatmentPartReturn;
exports.deleteTreatmentPartReturn = deleteTreatmentPartReturn;
exports.getTreatmentPartReturn = getTreatmentPartReturn;
exports.receiptTreatmentPartReturn = receiptTreatmentPartReturn;
exports.updateTreatmentPartReturn = updateTreatmentPartReturn;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreatmentPartReturn(query) {
  return (0, _request.default)({
    url: '/system/treatmentpartreturn/',
    method: 'get',
    params: query
  });
}
function createTreatmentPartReturn(data) {
  return (0, _request.default)({
    url: '/system/treatmentpartreturn/',
    method: 'post',
    data: data
  });
}
function updateTreatmentPartReturn(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartreturn/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteTreatmentPartReturn(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartreturn/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalTreatmentPartReturn(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartreturn/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
function receiptTreatmentPartReturn(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartreturn/".concat(id, "/receipt/"),
    method: 'patch',
    data: data
  });
}
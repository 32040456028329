"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTreater = createTreater;
exports.deleteTreater = deleteTreater;
exports.getTreater = getTreater;
exports.getTreaterList = getTreaterList;
exports.updateTreater = updateTreater;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreaterList(query) {
  return (0, _request.default)({
    url: '/system/treater/',
    method: 'get',
    params: query
  });
}
function getTreater(id) {
  return (0, _request.default)({
    url: "/system/treater/".concat(id, "/"),
    method: 'get'
  });
}
function createTreater(data) {
  return (0, _request.default)({
    url: '/system/treater/',
    method: 'post',
    data: data
  });
}
function updateTreater(id, data) {
  return (0, _request.default)({
    url: "/system/treater/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteTreater(id, data) {
  return (0, _request.default)({
    url: "/system/treater/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}

// export function getFilter(query) {
//   return request({
//     url: '/system/treater/filter/',
//     method: 'get',
//     params: query
//   })
// }
// export function changePassword(data) {
//   return request({
//     url: '/system/treater/password/',
//     method: 'put',
//     data
//   })
// }
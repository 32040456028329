"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _device_disc = require("@/api/device_disc");
var _xlsx = _interopRequireDefault(require("xlsx"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// secondary package based on el-pagination
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      date: '',
      dateTYpe: {
        'start_end_time': '',
        'end_end_time': ''
      },
      disc_status: '',
      centerDialogVisible: false,
      recordDiscList: {
        count: 0
      },
      listLoading: true,
      listQuery: {
        page: 1,
        page_size: 20
      },
      count: 0
      // sexType: {
      //   1: '未知',
      //   2: '男',
      //   3: '女'
      // }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _device_disc.getRecordDiscList)(this.listQuery).then(function (response) {
        if (response.data) {
          _this.count = response.data.count;
          _this.recordDiscList = response.data;
        }
        _this.listLoading = false;
      });
    },
    viewStatus: function viewStatus(scope) {
      // console.log(scope.row.telemetry)
      // console.log(scope.row.telemetry.replace("'","\""))
      if (scope.row.telemetry.length > 2) {
        this.disc_status = JSON.parse(scope.row.telemetry.replaceAll("'", "\""));
      } else {
        this.disc_status = ['没有数据'];
      }
      this.centerDialogVisible = true;
    },
    getDate: function getDate() {
      if (this.date != null) {
        this.listQuery.start_end_time = this.date[0];
        this.listQuery.end_end_time = this.date[1];
      } else {
        this.listQuery.start_end_time = '';
        this.listQuery.end_end_time = '';
      }
      this.handleFilter();
    },
    handleExport: function handleExport() {
      var _this2 = this;
      this.listLoading = true;
      var excelData = [['ID', '控释盘ID', '设备编号', '通道号', '控释盘使用次数', '开始时间', '结束时间', '日志']];
      var query = (0, _objectSpread2.default)({}, this.listQuery);
      query.page_size = 1000;
      (0, _device_disc.getRecordDiscList)(query).then(function (response) {
        _this2.table = response.data.results;
        for (var i = 0; i < _this2.table.length; i++) {
          _this2.rowData = [_this2.table[i].id, _this2.table[i].disc, _this2.table[i].device_number, _this2.table[i].channel, _this2.table[i].count, _this2.table[i].begin_time, _this2.table[i].end_time, _this2.table[i].telemetry];
          excelData.push(_this2.rowData);
        }
        var ws = _xlsx.default.utils.aoa_to_sheet(excelData);
        var wb = _xlsx.default.utils.book_new();
        _xlsx.default.utils.book_append_sheet(wb, ws, 'disc');
        _xlsx.default.writeFile(wb, '控释盘调理纪录.xlsx');
        _this2.listLoading = false;
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDiseaseClass = createDiseaseClass;
exports.deleteDiseaseClass = deleteDiseaseClass;
exports.getDiseaseClassAll = getDiseaseClassAll;
exports.getDiseaseClassDoctor = getDiseaseClassDoctor;
exports.updateDiseaseClass = updateDiseaseClass;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDiseaseClassAll() {
  return (0, _request.default)({
    url: '/system/diseaseclass/',
    method: 'get'
  });
}
function createDiseaseClass(data) {
  return (0, _request.default)({
    url: '/system/diseaseclass/',
    method: 'post',
    data: data
  });
}
function updateDiseaseClass(id, data) {
  return (0, _request.default)({
    url: "/system/diseaseclass/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDiseaseClass(id, data) {
  return (0, _request.default)({
    url: "/system/diseaseclass/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function getDiseaseClassDoctor(id) {
  return (0, _request.default)({
    url: "/system/diseaseclass/".concat(id, "/doctorinfo/"),
    method: 'get'
  });
}
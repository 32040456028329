"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGoods = createGoods;
exports.createStocks = createStocks;
exports.deleteGoods = deleteGoods;
exports.deleteGoodsImage = deleteGoodsImage;
exports.getGoodsCountbyPlanId = getGoodsCountbyPlanId;
exports.getGoodsList = getGoodsList;
exports.getGoodsListLight = getGoodsListLight;
exports.getOrdersCountbyGoodsId = getOrdersCountbyGoodsId;
exports.getStocksList = getStocksList;
exports.getTagsList = getTagsList;
exports.updateGoods = updateGoods;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getGoodsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods"),
    method: 'get',
    params: query
  });
}
// 商品清單（Light減肥版)
function getGoodsListLight(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods/list"),
    method: 'get',
    params: query
  });
}
function createGoods(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/goods"),
    method: 'post',
    data: data
  });
}
function updateGoods(id, data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/goods/").concat(id),
    method: 'put',
    data: data
  });
}
function deleteGoods(id) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods/").concat(id),
    method: 'delete'
  });
}
function getTagsList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/tags"),
    method: 'get',
    params: query
  });
}
function deleteGoodsImage(id) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_images/").concat(id),
    method: 'delete'
  });
}
// 庫存
function getStocksList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/stocks"),
    method: 'get',
    params: query
  });
}
function createStocks(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/stocks"),
    method: 'post',
    data: data
  });
}
//搜尋商品是否有被下單過
function getOrdersCountbyGoodsId(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/online_orders_goods_count"),
    method: 'get',
    params: query
  });
}
//搜尋方案是否有商品綁定
function getGoodsCountbyPlanId(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/goods_plan_count"),
    method: 'get',
    params: query
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPreparations = createPreparations;
exports.deletePreparations = deletePreparations;
exports.getPreparationsAll = getPreparationsAll;
exports.updatePreparations = updatePreparations;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPreparationsAll() {
  return (0, _request.default)({
    url: '/system/preparations/',
    method: 'get'
  });
}
function createPreparations(data) {
  return (0, _request.default)({
    url: '/system/preparations/',
    method: 'post',
    data: data
  });
}
function updatePreparations(id, data) {
  return (0, _request.default)({
    url: "/system/preparations/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deletePreparations(id, data) {
  return (0, _request.default)({
    url: "/system/preparations/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalTreatmentPartAdjust = approvalTreatmentPartAdjust;
exports.createTreatmentPartAdjust = createTreatmentPartAdjust;
exports.deleteTreatmentPartAdjust = deleteTreatmentPartAdjust;
exports.getTreatmentPartAdjust = getTreatmentPartAdjust;
exports.getTreatmentPartAdjustList = getTreatmentPartAdjustList;
exports.updateTreatmentPartAdjust = updateTreatmentPartAdjust;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreatmentPartAdjustList(query) {
  return (0, _request.default)({
    url: '/system/treatmentpartadjust/',
    method: 'get',
    params: query
  });
}
function getTreatmentPartAdjust(id) {
  return (0, _request.default)({
    url: "/system/treatmentpartadjust/".concat(id, "/"),
    method: 'get'
  });
}
function createTreatmentPartAdjust(data) {
  return (0, _request.default)({
    url: '/system/treatmentpartadjust/',
    method: 'post',
    data: data
  });
}
function updateTreatmentPartAdjust(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartadjust/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteTreatmentPartAdjust(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartadjust/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalTreatmentPartAdjust(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentpartadjust/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAdvertisement = createAdvertisement;
exports.createAdvertisementImage = createAdvertisementImage;
exports.getAdvertisementList = getAdvertisementList;
exports.updateAdvertisement = updateAdvertisement;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function getAdvertisementList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/advertisement"),
    method: 'get',
    params: query
  });
}
function createAdvertisement(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/advertisement"),
    method: 'post',
    data: data
  });
}
function updateAdvertisement(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/advertisement/").concat(id),
    method: 'put',
    data: data
  });
}
function createAdvertisementImage(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/advertisement/image"),
    method: 'post',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPlanOther = createPlanOther;
exports.deletePlanOther = deletePlanOther;
exports.getPlanOtherList = getPlanOtherList;
exports.updatePlanOther = updatePlanOther;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPlanOtherList(query) {
  return (0, _request.default)({
    url: '/system/planother/',
    method: 'get',
    params: query
  });
}
function createPlanOther(data) {
  return (0, _request.default)({
    url: '/system/planother/',
    method: 'post',
    data: data
  });
}
function updatePlanOther(id, data) {
  return (0, _request.default)({
    url: "/system/planother/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deletePlanOther(id, data) {
  return (0, _request.default)({
    url: "/system/planother/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
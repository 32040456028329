"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _order = require("@/api/order");
var _device = require("@/api/device");
var _xlsx = _interopRequireDefault(require("xlsx"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      date: '',
      typeOptions: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '0元订单'
      }, {
        value: 2,
        label: '非0元订单'
      }],
      selectOptions: [{
        value: 'appointment_medical_no',
        label: '机构编号'
      }, {
        value: 'appointment_medical_name',
        label: '机构名称'
      }, {
        value: 'create_user_id',
        label: '开单人编号'
      }, {
        value: 'create_user_name',
        label: '开单人姓名'
      }, {
        value: 'approval_user_id',
        label: '审核人编号'
      }, {
        value: 'approval_user_name',
        label: '审核人姓名'
      }, {
        value: 'treatment_plan_no',
        label: '调理方案编号'
      }, {
        value: 'treatment_plan_title',
        label: '调理方案名称'
      }],
      listLoading: true,
      listQuery: {
        page: 1
      },
      keyword: '',
      filters: '',
      filters1: '',
      order_quantity: '',
      treatmentpart_quantity: '',
      order_types: ''
    };
  },
  created: function created() {},
  methods: {
    checkPermission: _permission.default,
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.getOrderReportList)(this.listQuery).then(function (response) {
        if (response.data) {
          _this.order_quantity = response.data.order_quantity;
          _this.treatmentpart_quantity = response.data.treatmentpart_quantity;
          console.log(response.data);
        }
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      if (this.order_types === 0 || this.order_types === '') {
        delete this.listQuery.order_types;
      } else {
        this.listQuery.order_types = this.order_types;
      }
      if (this.listQuery.start_date === '' || this.listQuery.start_date === undefined || this.listQuery.start_date === null) {
        this.$message({
          type: 'error',
          message: '请先选择开始及结束查询时间!'
        });
      } else {
        this.getList();
      }
    },
    handleFilters: function handleFilters() {
      if (this.listQuery.start_date === '' || this.listQuery.start_date === undefined || this.listQuery.start_date === null) {
        this.$message({
          type: 'error',
          message: '请先选择开始及结束查询时间!'
        });
      } else if (this.filters) {
        var data2 = this.filters;
        this.filters1 = this.filters;
        this.listQuery[data2] = this.keyword;
        console.log(this.listQuery);
        this.getList();
      } else {
        console.log(this.filters1);
        this.listQuery[this.filters1] = '';
        this.getList();
      }
    },
    getDate: function getDate() {
      if (this.date != null) {
        this.listQuery.start_date = this.date[0];
        this.listQuery.end_date = this.date[1];
      }
      this.handleFilter();
    },
    clear: function clear() {
      this.keyword = '';
    },
    handleExport: function handleExport() {
      var excelData = [['设备数量(笔)', '部位数量(个)']];
      var rowData = [this.order_quantity, this.treatmentpart_quantity];
      excelData.push(rowData);
      console.log(excelData);
      var ws = _xlsx.default.utils.aoa_to_sheet(excelData);
      var wb = _xlsx.default.utils.book_new();
      _xlsx.default.utils.book_append_sheet(wb, ws, 'order_report');
      _xlsx.default.writeFile(wb, 'order_report.xlsx');
    }
  }
};
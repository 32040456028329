"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDevice = createDevice;
exports.deleteDevice = deleteDevice;
exports.getDevice = getDevice;
exports.getDeviceErrorList = getDeviceErrorList;
exports.getDeviceExport = getDeviceExport;
exports.getDeviceList = getDeviceList;
exports.getDeviceModelList = getDeviceModelList;
exports.getDeviceTransformList = getDeviceTransformList;
exports.updateDevice = updateDevice;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDeviceList(query) {
  return (0, _request.default)({
    url: '/system/device/',
    method: 'get',
    params: query
  });
}
function getDeviceModelList(query) {
  return (0, _request.default)({
    url: '/system/device/model/',
    method: 'get',
    params: query
  });
}
function getDevice(id) {
  return (0, _request.default)({
    url: "/system/device/".concat(id, "/"),
    method: 'get'
  });
}
function createDevice(data) {
  return (0, _request.default)({
    url: '/system/device/',
    method: 'post',
    data: data
  });
}
function updateDevice(id, data) {
  return (0, _request.default)({
    url: "/system/device/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDevice(id, data) {
  return (0, _request.default)({
    url: "/system/device/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function getDeviceTransformList(query) {
  return (0, _request.default)({
    url: '/system/deviceroam/',
    method: 'get',
    params: query
  });
}
function getDeviceErrorList(query) {
  return (0, _request.default)({
    url: '/system/deviceerror/',
    method: 'get',
    params: query
  });
}
function getDeviceExport() {
  return (0, _request.default)({
    url: '/system/device/export/',
    method: 'get'
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCommissionBranches = createCommissionBranches;
exports.getCommissionBranch = getCommissionBranch;
exports.getCommissionBranches = getCommissionBranches;
exports.getQuDaoReport = getQuDaoReport;
exports.updateCommissionBranch = updateCommissionBranch;
var _request = _interopRequireDefault(require("@/utils/request"));
function createCommissionBranches(data) {
  return (0, _request.default)({
    url: '/system/commissionbranchesdetail/',
    method: 'post',
    data: data
  });
}
function getCommissionBranches(query) {
  return (0, _request.default)({
    url: '/system/commissionbranchesdetail/',
    method: 'get',
    params: query
  });
}
function getCommissionBranch(id) {
  return (0, _request.default)({
    url: "/system/commissionbranchesdetail/".concat(id),
    method: 'get'
  });
}
function updateCommissionBranch(id, data) {
  return (0, _request.default)({
    url: "/system/commissionbranchesdetail/".concat(id, "/"),
    method: 'patch',
    data: data
  });
}
function getQuDaoReport(data) {
  return (0, _request.default)({
    url: '/system/qudaoreport/',
    method: 'post',
    data: data
  });
}
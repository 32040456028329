"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalOrder = approvalOrder;
exports.createOrder = createOrder;
exports.deleteOrder = deleteOrder;
exports.getOrderList = getOrderList;
exports.getOrderReportList = getOrderReportList;
exports.shipmentOrder = shipmentOrder;
exports.updateOrder = updateOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
function getOrderList(query) {
  return (0, _request.default)({
    url: '/system/order/',
    method: 'get',
    params: query
  });
}
function createOrder(data) {
  return (0, _request.default)({
    url: '/system/order/',
    method: 'post',
    data: data
  });
}
function updateOrder(id, data) {
  return (0, _request.default)({
    url: "/system/order/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteOrder(id, data) {
  return (0, _request.default)({
    url: "/system/order/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalOrder(id, data) {
  return (0, _request.default)({
    url: "/system/order/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
function shipmentOrder(id, data) {
  return (0, _request.default)({
    url: "/system/order/".concat(id, "/shipment/"),
    method: 'patch',
    data: data
  });
}
function getOrderReportList(query) {
  return (0, _request.default)({
    url: '/system/ordereport/',
    method: 'get',
    params: query
  });
}
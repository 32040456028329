"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _inventory = require("@/api/inventory");
var _region = require("@/api/region");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      provinceData: [],
      cityData: [],
      districtData: [],
      org: {
        dept: '',
        province_code: '',
        city_code: '',
        district_code: ''
      },
      device_quantity: '',
      treatmentpart_quantity: '',
      profitOptions: [{
        key: 1,
        display_name: '渠道商'
      }, {
        key: 2,
        display_name: '运维团队'
      }, {
        key: 3,
        display_name: '设备所属单位'
      }, {
        key: 4,
        display_name: '医疗机构'
      }, {
        key: 5,
        display_name: '调理间'
      }, {
        key: 6,
        display_name: '处方所有人'
      }, {
        key: 10,
        display_name: '新汉方公司'
      }]
    };
  },
  created: function created() {
    this.getProvinceAll();
    this.getCityAll();
    this.getDistrictAll();
  },
  methods: {
    checkPermission: _permission.default,
    getList: function getList() {
      var _this = this;
      if (this.org.dept === '' && this.org.province_code === '' && this.org.city_code === '' && this.org.district_code === '') {
        this.device_quantity = '';
        this.treatmentpart_quantity = '';
      } else {
        (0, _inventory.getInventory)(this.org).then(function (response) {
          _this.tableData = response.data;
          _this.device_quantity = response.data.device_quantity;
          _this.treatmentpart_quantity = response.data.treatmentpart_quantity;
        });
      }
    },
    getProvinceAll: function getProvinceAll() {
      var _this2 = this;
      (0, _region.getProvinceAll)().then(function (response) {
        _this2.provinceData = response.data;
      });
    },
    provinceChange: function provinceChange(val) {
      var _this3 = this;
      this.cityData = [];
      this.org.city_code = '';
      this.org.district_code = '';
      this.defaultCity.forEach(function (item) {
        var item_city = Object.assign({}, item);
        _this3.cityData.push(item_city);
      });
      var province_code = this.provinceData.filter(function (item) {
        return item.id === val;
      })[0].province_code;
      this.cityData = this.cityData.filter(function (item) {
        return item.province_code === province_code;
      });
      this.getList();
    },
    getCityAll: function getCityAll() {
      var _this4 = this;
      (0, _region.getCityAll)().then(function (response) {
        _this4.defaultCity = response.data;
        _this4.cityData = [];
        _this4.defaultCity.forEach(function (item) {
          var item_city = Object.assign({}, item);
          _this4.cityData.push(item_city);
        });
      });
    },
    cityChange: function cityChange(val) {
      var _this5 = this;
      this.districtData = [];
      this.org.district_code = '';
      this.defaultDistrict.forEach(function (item) {
        var item_district = Object.assign({}, item);
        _this5.districtData.push(item_district);
      });
      var city_code = this.cityData.filter(function (item) {
        return item.id === val;
      })[0].city_code;
      this.districtData = this.districtData.filter(function (item) {
        return item.city_code === city_code;
      });
      this.getList();
    },
    getDistrictAll: function getDistrictAll() {
      var _this6 = this;
      (0, _region.getDistrictAll)().then(function (response) {
        _this6.defaultDistrict = response.data;
        _this6.districtData = [];
        _this6.defaultDistrict.forEach(function (item) {
          var item_district = Object.assign({}, item);
          _this6.districtData.push(item_district);
        });
      });
      this.getList();
    },
    clearData: function clearData() {
      this.getList();
    }
  }
};
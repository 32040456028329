"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _vueQuillEditor = require("vue-quill-editor");
var _quillImageResizeModule = _interopRequireDefault(require("quill-image-resize-module"));
var _mixinsEditor = _interopRequireDefault(require("./mixinsEditor"));
var _quillImageExtendModule = require("quill-image-extend-module");
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("@/api/_homeCloudPlatform/_config");
var _quillFont = _interopRequireDefault(require("@/styles/_homeCloudPlatform/quillFont.scss"));
//
//
//
//
//
//
//
//
//
//
//
//

_vueQuillEditor.Quill.register('modules/imageResize', _quillImageResizeModule.default);
_vueQuillEditor.Quill.register('modules/ImageExtend', _quillImageExtendModule.ImageExtend);
// ----------------------------------
// 字形
var fontFamilyArr = ['cursive', 'fantasy', 'monospace', 'serif', 'sans-serif', '宋体', '仿宋', '隶书', '细明体', '新细明体', '标楷体', '微软正黑体'];
var fonts = _vueQuillEditor.Quill.import('formats/font');
fonts.whitelist = fontFamilyArr;
_vueQuillEditor.Quill.register(fonts, true);
// -----------
// fontSize

var fontSizeArr = _quillFont.default.fontSizeArr.replaceAll('"', '').split(',');
var Size = _vueQuillEditor.Quill.import('formats/size');
Size.whitelist = fontSizeArr;
// ----------------------------------
var _default = exports.default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  mixins: [_mixinsEditor.default],
  props: ['editor', 'type'],
  watch: {
    editor: {
      immediate: true,
      handler: function handler() {
        this.content = JSON.parse(JSON.stringify(this.editor || ''));
      }
    }
  },
  mounted: function mounted() {},
  data: function data() {
    return {
      content: '',
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{
              'header': 1
            }, {
              'header': 2
            }], [{
              'list': 'ordered'
            }, {
              'list': 'bullet'
            }], [{
              'script': 'sub'
            }, {
              'script': 'super'
            }], [{
              'indent': '-1'
            }, {
              'indent': '+1'
            }], [{
              'direction': 'rtl'
            }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],
            [{
              'size': fontSizeArr
            }],
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{
              'font': fontFamilyArr
            }], [{
              'color': []
            }, {
              'background': []
            }],
            // [{ 'font': [] }],
            [{
              'align': []
            }], ['clean'], ['link', 'image']],
            handlers: {
              'image': function image() {
                var quillInstance = this.quill;
                var range = quillInstance.getSelection();
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.addEventListener('change', function () {
                  var file = input.files[0];
                  var isLt2M = file.size / 1024 / 1024 < 0.5;
                  if (isLt2M) {
                    var formdata = new FormData();
                    formdata.append('file', file);
                    return (0, _request.default)({
                      url: "".concat(_config.domain, "/goods_images"),
                      method: 'post',
                      data: formdata
                    }).then(function (res) {
                      var imageUrl = res.data.urls;
                      quillInstance.insertEmbed(range.index, 'image', imageUrl);
                    });
                  } else {
                    alert('上传图片大小不能超过 500kb!');
                    input.value = '';
                  }
                });
                input.click();
              }
            }
          }
          // imageResize: {
          //   // 右下角圖片規格
          //   displayStyles: {
          //     backgroundColor: 'black',
          //     border: 'none',
          //     color: 'white'
          //   },
          //   modules: ['Resize', 'DisplaySize', 'Toolbar']
          // }
        },
        placeholder: '请输入内容...',
        readOnly: false
      },
      scrollTop: 0
    };
  },
  methods: {
    // 預設字形
    defaultFont: function defaultFont() {
      document.querySelector('[data-value="14px"]').click();
      document.querySelector('[data-value="微软正黑体"]').click();
    },
    editorReadyHook: function editorReadyHook(editor) {
      // unfocus fixed scroll
      document.querySelector('.ql-container').addEventListener('scroll', this.debounce(this.handlePageScroll, 300));
      this.defaultFont();
    },
    editorChangeHook: function editorChangeHook(e) {
      if (!e.html.length) {
        this.defaultFont();
      }
      this.$emit('update:editor', e.html);
      document.querySelector('.ql-container').scrollTop = this.scrollTop;
    },
    handlePageScroll: function handlePageScroll() {
      this.scrollTop = document.querySelector('.ql-container').scrollTop;
    },
    debounce: function debounce(func, delay) {
      var _arguments = arguments,
        _this = this;
      var timeout = null;
      return function () {
        var context = _this;
        var args = _arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, delay);
      };
    }
  }
};
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDiseaseList = createDiseaseList;
exports.deleteDiseaseList = deleteDiseaseList;
exports.getDiseaseListAll = getDiseaseListAll;
exports.updateDiseaseList = updateDiseaseList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDiseaseListAll() {
  return (0, _request.default)({
    url: '/system/diseaselist/',
    method: 'get'
  });
}
function createDiseaseList(data) {
  return (0, _request.default)({
    url: '/system/diseaselist/',
    method: 'post',
    data: data
  });
}
function updateDiseaseList(id, data) {
  return (0, _request.default)({
    url: "/system/diseaselist/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDiseaseList(id, data) {
  return (0, _request.default)({
    url: "/system/diseaselist/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/code/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _device = require("@/api/device");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//假資料
var defaultofflinsales = {
  ordermessage: {
    order_price: '123',
    receiving_medical_no: '',
    receiving_medical_name: '',
    delivery_time: '',
    sales_no: '',
    sales_name: '',
    remark: ''
  },
  device: {
    model: '',
    quantity: '3',
    device_attr: '',
    device_attributes: '',
    equipment_owner: ''
  },
  steaming: {
    model: '1',
    quantity: '5',
    device_attr: '',
    device_attributes: '',
    equipment_owner: '',
    usage_range: ''
  },
  treatmentquantity: [{
    treatmentype: '',
    quantity: ''
  }],
  shipinfor: {
    time: '',
    company: '',
    shipnumber: '',
    devicenumber: '',
    streamnumber: '',
    remark: ''
  }
};
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      listLoading: false,
      dialogVisible: false,
      activeName: 'orderInfo',
      dialogType: '',
      disabled: false,
      tableData: {
        count: 0
      },
      deviceModelList: [],
      listQuery: {
        page: 1,
        page_size: 10,
        status: ''
      },
      optionRecordData: [],
      totalquantity: 0,
      rules: {
        price: [{
          required: true,
          message: '请输入订单金额'
        }],
        medical_no: [{
          required: true,
          message: '请输入收货医疗机构编号'
        }]
        // time:[
        //   { },
        // ],
      },
      statusOptions: [{
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '待发货'
      }, {
        value: 3,
        label: '拒绝'
      }, {
        value: 4,
        label: '完成'
      }]
    }, "tableData", {
      count: 0,
      results: [{
        id: 1,
        status: 3,
        sales_order_no: 123123,
        amount: 1000,
        time: "20230807",
        receiving_medical_no: 202111,
        receiving_medical_name: "山東診所",
        data: {
          ordermessage: {
            ordernumber: 122222,
            order_price: 1233,
            state: '拒绝',
            receiving_medical_no: '23',
            receiving_medical_name: 'fake',
            receiving_medical_address: 'fakefakefake',
            receiving_medical_contact: 'faker',
            receiving_medical_tel: '123456',
            delivery_time: '',
            sales_no: '',
            sales_name: '',
            remark: '',
            review: '',
            review_remark: '',
            apply_no: 12,
            apply_name: '白白',
            apply_time: ''
          },
          device: {
            model: 'ASP124',
            quantity: '',
            device_attr: '',
            device_attributes: '',
            equipment_owner: ''
          },
          steaming: {
            model: 'ASP124',
            quantity: '',
            device_attr: '',
            device_attributes: '',
            equipment_owner: '',
            usage_range: ''
          },
          treatmentquantity: [{
            treatmentype: '',
            quantity: ''
          }],
          shipinfor: {
            time: '',
            company: '',
            shipnumber: '',
            devicenumber: '',
            streamnumver: '',
            remark: ''
          }
        }
      }, {
        id: 2,
        status: 2,
        sales_order_no: 123123,
        amount: 1000,
        time: "20230807",
        receiving_medical_no: 202111,
        receiving_medical_name: "山西醫院",
        data: {
          ordermessage: {
            ordernumber: 122222,
            order_price: 1233,
            state: '待发货',
            receiving_medical_no: '',
            receiving_medical_name: '',
            delivery_time: '',
            sales_no: '',
            sales_name: '',
            remark: ''
          },
          device: {
            model: 'ASP124',
            quantity: '',
            device_attr: '',
            device_attributes: '',
            equipment_owner: ''
          },
          steaming: {
            model: 'ASP124',
            quantity: '',
            device_attr: '',
            device_attributes: '',
            equipment_owner: '',
            usage_range: ''
          },
          treatmentquantity: [{
            treatmentype: '',
            quantity: ''
          }],
          shipinfor: {
            time: '',
            company: '',
            shipnumber: '',
            devicenumber: '',
            streamnumver: '',
            remark: ''
          }
        }
      }, {
        id: 3,
        status: 4,
        sales_order_no: 123123,
        amount: 10,
        time: "20230807",
        receiving_medical_no: 202111,
        receiving_medical_name: "湖北醫院",
        data: {}
      }, {
        id: 4,
        status: 1,
        sales_order_no: 123123,
        amount: 1000,
        time: "20230807",
        receiving_medical_no: 202111,
        receiving_medical_name: "湖北醫院",
        data: {}
      }]
    }), "treatmentype_list", [{
      value: '选项1',
      label: '1号方(A方)'
    }, {
      value: '选项2',
      label: '2号方(B方)'
    }, {
      value: '选项3',
      label: '3号方(C方)'
    }, {
      value: '选项4',
      label: '4号方(D方)'
    }]), "offlinsales", defaultofflinsales), "ordermessage", defaultofflinsales.ordermessage), "orderdevice", defaultofflinsales.device), "ordersteaming", defaultofflinsales.steaming), "ordertreatmentpartsalequantity", defaultofflinsales.treatmentquantity), "ordership", defaultofflinsales.shipinfor), "optionRecordQuery", {
      online_order: "",
      page: 1,
      page_size: 10,
      count: 0,
      ordering: '',
      switch: true
    });
  },
  created: function created() {
    this.getDeviceModelList();
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    getList: function getList() {
      this.listLoading = true;
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    checkPermission: _permission.default,
    getDeviceModelList: function getDeviceModelList() {
      var _this = this;
      (0, _device.getDeviceModelList)().then(function (response) {
        _this.deviceModelList = response.data;
      });
    },
    handleAdd: function handleAdd() {
      // this.devicesalesorder = Object.assign({}, defaultDeviceSalesOrder)
      this.dialogVisible = true;
      this.disabled = false;
      this.dialogType = 'new';
    },
    handleClick: function handleClick() {},
    handleRecordList: function handleRecordList() {},
    addQuantity: function addQuantity() {
      var sum = 0;
      for (var i = 0; i < this.ordertreatmentpartsalequantity.length; i++) {
        sum = sum + Number(this.ordertreatmentpartsalequantity[i].quantity);
      }
      this.totalquantity = sum;
    },
    addtreatment: function addtreatment() {
      this.ordertreatmentpartsalequantity.push({
        treatmentype: '',
        treatmentype_list: [{
          value: '1',
          label: '1号方(A方)'
        }, {
          value: '2',
          label: '2号方(B方)'
        }, {
          value: '3',
          label: '3号方(C方)'
        }, {
          value: '4',
          label: '4号方(D方)'
        }],
        quantity: ''
      });
    },
    removetreatment: function removetreatment(index) {
      if (this.ordertreatmentpartsalequantity.length > 1) {
        this.ordertreatmentpartsalequantity.splice(index, 1);
      } else {
        this.ordertreatmentpartsalequantity[0].treatmentype = '';
        this.ordertreatmentpartsalequantity[0].quantity = '';
        this.ordertreatmentpartsalequantity[0].totalquantity = '';
      }
      this.addQuantity();
    },
    // TODO get 操作紀錄
    getOptionRecordList: function getOptionRecordList(id) {
      this.listLoading = true;
      this.optionRecordQuery.online_order = id;
      this.optionRecordQuery.page = 1;
      // 串接後端api
      // getWorkingLog(this.optionRecordQuery
      // ).then(res => {
      //   this.optionRecordQuery.count=res.count
      //   this.optionRecordData = res.data || []
      //   this.listLoading = false
      // })
    },
    handleEdit: function handleEdit(scope) {
      this.dialogType = 'edit';
      this.dialogVisible = true;
      this.disabled = true;
      this.offlinsales = scope.row.data;
    },
    handleView: function handleView(scope) {
      this.dialogVisible = true;
      this.dialogType = 'view';
      this.disabled = true;
      this.offlinsales = scope.row.data;
    }
  }, "handleRecordList", function handleRecordList() {
    // 串接後端api (需新增一個新的request ，getWorkingLog範例是商城管理操作紀錄使用)
    // getWorkingLog(this.optionRecordQuery).then(res => {
    //   this.optionRecordData = res.data || []
    //   this.listLoading = false
    // })
  }), "handleRecordSort", function handleRecordSort(column) {
    var ordering = "";
    var turn = "";
    if (column) {
      switch (column.prop) {
        case '操作时间':
          ordering = "working_time";
          break;
        case '操作人员':
          ordering = "name";
          break;
        case '内容':
          ordering = "working_log";
          break;
      }
      if (column.order == "descending") {
        turn = '-';
      }
      this.optionRecordQuery.ordering = turn + ordering;
      this.optionRecordQuery.page = 1;
    }
    // 串接後端api

    // getWorkingLog(this.optionRecordQuery).then(res => {
    //   this.optionRecordData = res.data || []
    //   this.listLoading = false
    // })
  }), "confirm", function confirm(form) {
    this.dialogVisible = false;
  })
};
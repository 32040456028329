"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInventory = getInventory;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInventory(query) {
  return (0, _request.default)({
    url: '/system/inventory/',
    method: 'get',
    params: query
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addStoreConsole = addStoreConsole;
exports.createGroupImage = createGroupImage;
exports.createGroupList = createGroupList;
exports.createGroupStoreDevice = createGroupStoreDevice;
exports.createStoreImage = createStoreImage;
exports.getDeviceL1Menu = getDeviceL1Menu;
exports.getDeviceL2Menu = getDeviceL2Menu;
exports.getGroupLevelList = getGroupLevelList;
exports.getGroupList = getGroupList;
exports.getStoreDeviceDisc = getStoreDeviceDisc;
exports.getStoreDiscRecord = getStoreDiscRecord;
exports.getStoreList = getStoreList;
exports.getStoreTreatmentRecord = getStoreTreatmentRecord;
exports.updateGroupList = updateGroupList;
exports.updateStoreDisc = updateStoreDisc;
exports.updateStoreList = updateStoreList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
// 合作機構
function getGroupList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group"),
    method: 'get',
    params: query
  });
}
function createGroupList(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group"),
    method: 'post',
    data: data
  });
}
function updateGroupList(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/").concat(id),
    method: 'put',
    data: data
  });
}
function createGroupImage(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/group/image"),
    method: 'post',
    data: data
  });
}

// 合作機構等級
function getGroupLevelList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/level"),
    method: 'get',
    params: query
  });
}

//查詢店鋪列表
function getStoreList(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store"),
    method: 'get',
    params: query
  });
}
//修改店鋪母片
function updateStoreList(id, data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/").concat(id),
    method: 'put',
    data: data
  });
}
//上傳店鋪認證圖片
function createStoreImage(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: "".concat(_config.domain, "/group/store/image"),
    method: 'post',
    data: data
  });
}
//檢測設備分類表一級编码清單
function getDeviceL1Menu(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/device/level1/menu"),
    method: 'get',
    params: query
  });
}
//檢測設備分類表二級编码清單
function getDeviceL2Menu(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/device/level2/menu"),
    method: 'get',
    params: query
  });
}
//查詢店鋪裝置治療紀錄
function getStoreTreatmentRecord(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/treatment/record"),
    method: 'get',
    params: query
  });
}
//新增店鋪
function addStoreConsole(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/console"),
    method: 'post',
    data: data
  });
}
//查詢合作機構店鋪盤次
function getStoreDiscRecord(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/disc"),
    method: 'get',
    params: query
  });
}

//異動合作機構店鋪盤次
function updateStoreDisc(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/disc"),
    method: 'post',
    data: data
  });
}

//查詢店鋪設備和控釋盤清單
function getStoreDeviceDisc(query) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/console"),
    method: 'get',
    params: query
  });
}

// 新增合作機構店鋪管理
function createGroupStoreDevice(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/group/store/console/device"),
    method: 'post',
    data: data
  });
}
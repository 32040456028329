"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approvalDeviceReturn = approvalDeviceReturn;
exports.createDeviceReturn = createDeviceReturn;
exports.deleteDeviceReturn = deleteDeviceReturn;
exports.getDeviceReturn = getDeviceReturn;
exports.receiptDeviceReturn = receiptDeviceReturn;
exports.updateDeviceReturn = updateDeviceReturn;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDeviceReturn(query) {
  return (0, _request.default)({
    url: '/system/devicereturn/',
    method: 'get',
    params: query
  });
}
function createDeviceReturn(data) {
  return (0, _request.default)({
    url: '/system/devicereturn/',
    method: 'post',
    data: data
  });
}
function updateDeviceReturn(id, data) {
  return (0, _request.default)({
    url: "/system/devicereturn/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteDeviceReturn(id, data) {
  return (0, _request.default)({
    url: "/system/devicereturn/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
function approvalDeviceReturn(id, data) {
  return (0, _request.default)({
    url: "/system/devicereturn/".concat(id, "/approval/"),
    method: 'patch',
    data: data
  });
}
function receiptDeviceReturn(id, data) {
  return (0, _request.default)({
    url: "/system/devicereturn/".concat(id, "/receipt/"),
    method: 'patch',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }
  return config;
}, function (error) {
  // do something with request error
  // console.log(error) // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  res.code = res.code || response.status;
  if (res.code >= 200 && res.code < 400) {
    return res;
  }
  if (res.code === 401) {
    if (res.msg.indexOf('No active account') != -1) {
      (0, _elementUi.Message)({
        message: '用户名或密码错误',
        type: 'error',
        duration: 3 * 1000
      });
    } else {
      _elementUi.MessageBox.confirm('认证失败,请重新登陆.', '确认退出', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
  } else if (res.code >= 400) {
    console.log(res.data);
    if (res.data === null || res.data === undefined || res.data.length === 0) {
      console.log('res.msg');
      var _loop = function _loop(key) {
        setTimeout(function () {
          (0, _elementUi.Message)({
            message: res.msg[key][0] || '请求出错',
            type: 'error',
            duration: 3 * 1000
          });
        }, 10);
      };
      for (var key in res.msg) {
        _loop(key);
      }
    } else {
      console.log('res.data');
      var _loop2 = function _loop2(key1) {
        // console.log(res.data[key1][0])
        setTimeout(function () {
          (0, _elementUi.Message)({
            message: res.data[key1][0] || '请求出错',
            type: 'error',
            duration: 3 * 1000
          });
        }, 10);
      };
      for (var key1 in res.data) {
        _loop2(key1);
      }
    }
    return Promise.reject(new Error(res.msg || '请求出错'));
  }
}, function (error) {
  // console.log(error,response) // for debug
  // Message({
  //   message: "服务器错误",
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  return Promise.reject(error);
});
var _default = exports.default = service;
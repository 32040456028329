"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPatientList = getPatientList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPatientList(query) {
  return (0, _request.default)({
    url: '/system/huser/',
    method: 'get',
    params: query
  });
}

// export function getPatient(id) {
//   return request({
//     url: `/system/huser/${id}/`,
//     method: 'get'
//   })
// }

// export function createPatient(data) {
//   return request({
//     url: '/system/huser/',
//     method: 'post',
//     data
//   })
// }

// export function updatePatient(id, data) {
//   return request({
//     url: `/system/huser/${id}/`,
//     method: 'put',
//     data
//   })
// }

// export function deletePatient(id, data) {
//   return request({
//     url: `/system/huser/${id}/`,
//     method: 'delete',
//     data
//   })
// }

// export function changePassword(data) {
//   return request({
//     url: '/system/huser/password/',
//     method: 'put',
//     data
//   })
// }
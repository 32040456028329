"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetDevice = resetDevice;
var _request = _interopRequireDefault(require("@/utils/request"));
var _config = require("../_config");
function resetDevice(data) {
  return (0, _request.default)({
    url: "".concat(_config.domain, "/device/reset/settings"),
    method: 'patch',
    data: data
  });
}
"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTreatmentPlan = createTreatmentPlan;
exports.deleteTreatmentPlan = deleteTreatmentPlan;
exports.getTreatmentPlanList = getTreatmentPlanList;
exports.updateTreatmentPlan = updateTreatmentPlan;
var _request = _interopRequireDefault(require("@/utils/request"));
function getTreatmentPlanList(query) {
  return (0, _request.default)({
    url: '/system/treatmentplan/',
    method: 'get',
    params: query
  });
}
function createTreatmentPlan(data) {
  return (0, _request.default)({
    url: '/system/treatmentplan/',
    method: 'post',
    data: data
  });
}
function updateTreatmentPlan(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentplan/".concat(id, "/"),
    method: 'put',
    data: data
  });
}
function deleteTreatmentPlan(id, data) {
  return (0, _request.default)({
    url: "/system/treatmentplan/".concat(id, "/"),
    method: 'delete',
    data: data
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _region = require("@/api/region");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    /*
      UI 類型
      預設select,否則text
        input: displayType="text" 
    */
    displayType: {
      type: String,
      default: 'select'
    },
    // 是否reset
    reset: {
      type: Boolean,
      default: false
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // 是否禁選
    disabled: {
      type: Boolean,
      default: false
    },
    provinceCode: {
      type: [String, Number],
      default: 0
    },
    cityCode: {
      type: [String, Number],
      default: 0
    },
    districtCode: {
      type: [String, Number],
      default: 0
    }
  },
  watch: {
    // dialogVisible hook
    dialogVisible: {
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.province_code = this.provinceCode + '';
          this.city_code = this.cityCode + '';
          this.district_code = this.districtCode + '';
        }
      }
    },
    // reset hook
    reset: {
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.province_code = '';
          this.city_code = '';
          this.district_code = '';
        }
      }
    }
  },
  computed: {
    // 省連動城市[]
    cityData: function cityData() {
      var _this = this;
      if (!this.province_code) return [];
      var arr = [];
      this.defaultCity.forEach(function (item) {
        var item_city = Object.assign({}, item);
        arr.push(item_city);
      });
      var province_data = this.provinceData.filter(function (item) {
        return item.province_code === _this.province_code;
      });
      var province_code = province_data.length > 0 ? province_data[0].province_code : '';
      arr = arr.filter(function (item) {
        return item.province_code === province_code;
      });
      return arr;
    },
    // 城市連動區[]
    districtData: function districtData() {
      var _this2 = this;
      if (!this.city_code) return [];
      var arr = [];
      this.defaultDistrict.forEach(function (item) {
        var item_city = Object.assign({}, item);
        arr.push(item_city);
      });
      var city_data = this.cityData.filter(function (item) {
        return item.city_code === _this2.city_code;
      });
      var city_code = city_data.length > 0 ? city_data[0].city_code : '';
      arr = arr.filter(function (item) {
        return item.city_code === city_code;
      });
      return arr;
    },
    textData: function textData() {
      var _this3 = this;
      var provinceArr = this.provinceData.filter(function (item) {
        return item.province_code === _this3.province_code;
      });
      var cityArr = this.defaultCity.filter(function (item) {
        return item.city_code === _this3.city_code;
      });
      var districtArr = this.defaultDistrict.filter(function (item) {
        return item.district_code === _this3.district_code;
      });
      var str = '';
      if (provinceArr.length) {
        str += provinceArr[0].province_name;
      }
      if (cityArr.length) {
        str += cityArr[0].city_name;
      }
      if (districtArr.length) {
        str += districtArr[0].district_name;
      }
      return str;
    }
  },
  data: function data() {
    return {
      province_code: '',
      city_code: '',
      district_code: '',
      // 地址
      provinceData: [],
      // orignData
      defaultCity: [],
      defaultDistrict: []
    };
  },
  created: function created() {
    this.getProvinceAll();
    this.getCityAll();
    this.getDistrictAll();
  },
  methods: {
    // 省
    getProvinceAll: function getProvinceAll() {
      var _this4 = this;
      (0, _region.getProvinceAll)().then(function (response) {
        _this4.provinceData = response.data;
      });
    },
    provinceChange: function provinceChange(val) {
      this.city_code = '';
      this.district_code = '';
      this.$emit('update:provinceCode', val);
      this.$emit('update:cityCode', '');
      this.$emit('update:districtCode', '');
    },
    // 市
    getCityAll: function getCityAll() {
      var _this5 = this;
      (0, _region.getCityAll)().then(function (response) {
        _this5.defaultCity = response.data;
      });
    },
    cityChange: function cityChange(val) {
      this.district_code = '';
      this.$emit('update:cityCode', val);
      this.$emit('update:districtCode', '');
    },
    // 區
    getDistrictAll: function getDistrictAll() {
      var _this6 = this;
      (0, _region.getDistrictAll)().then(function (response) {
        _this6.defaultDistrict = response.data;
      });
    },
    districtChange: function districtChange(val) {
      this.$emit('update:districtCode', val);
    }
  }
};
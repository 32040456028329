"use strict";

var _interopRequireDefault = require("/code/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultOrder = {};
var _default = exports.default = {
  data: function data() {
    return {
      dialogTransformLoading: false,
      transformData: {
        results: []
      },
      listLoading: true,
      tableData: [],
      order: defaultOrder,
      dialogVisible: false,
      approvalDialog: false,
      viewDialog: false,
      shipmentDialog: false,
      dialogType: 'new',
      number: '',
      rule1: {},
      statusOptions: [{
        value: 'PRE-PAY',
        label: '待支付'
      }, {
        value: 'PAID-PAY',
        label: '已支付'
      }, {
        value: 'REFUND-VERIFY',
        label: '退款审核中'
      }, {
        value: 'REFUND',
        label: '退款中'
      }, {
        value: 'VERIFYED-PAY',
        label: '已审核'
      }, {
        value: 'FINISHED-PAY',
        label: '已完成'
      }, {
        value: 'PART-PAY',
        label: '部分调理'
      }, {
        value: 'REFUND-FINISHED',
        label: '退款完成'
      }, {
        value: 'REFUND-FAILED',
        label: '退款失败'
      }, {
        value: 'PRE-CANCEL',
        label: '订单取消'
      }, {
        value: 'TO-BE-TREATED',
        label: '待调理'
      }],
      statusOptions1: [{
        value: 'PRE-PAY',
        label: '待支付'
      }, {
        value: 'PAID-PAY',
        label: '已支付'
      }, {
        value: 'PRE-CANCEL',
        label: '已取消'
      }, {
        value: 'TO-BE-TREATED',
        label: '待调理'
      }, {
        value: 'PART-PAY',
        label: '部分调理'
      }, {
        value: 'FINISHED-PAY',
        label: '已完成'
      }],
      listQuery: {
        page: 1,
        page_size: 10
      },
      typeOptions: [{
        value: 1,
        label: '系统'
      }, {
        value: 2,
        label: '互联网医院'
      }],
      orgList: [],
      columnList: ['商品名称', '商品数量']
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getStatus: function getStatus(status) {
      if (status !== null && status !== '') {
        console.log(status);
        var index = this.statusOptions.findIndex(function (x) {
          return x.value === status;
        });
        console.log(index);
        status = this.statusOptions[index]['label'];
      }
      return status;
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.listQuery.page_size = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.listQuery.page = val;
      this.getList();
    },
    checkPermission: _permission.default,
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _order.getOrderList)(this.listQuery).then(function (response) {
        if (response.data) {
          // console.log(response.data)
          _this.tableData = response.data;
        }
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleView: function handleView(scope) {
      this.transformData.results = [];
      this.order = Object.assign({}, scope.row);
      this.viewDialog = true;
      this.transformData.results.push(this.order);
      console.log(this.transformData);
    },
    handleSend: function handleSend() {}
  }
};